/* variables (placeholder to let gulp run smoothly, get overridden anyway) */
@badge-danger-background: #00ff00;
@badge-info-background: #23c6c8;
@badge-warning-background: #ffd500 !important;
@btn-3d-active-color: @btn-edit-hover-color;
@btn-3d-active-background: @btn-edit-hover-background;
@btn-success-background: #1e8961;
@btn-success-border: #1e8961;
@btn-success-color: #fff;
@btn-success-hover-background: #fff;
@btn-success-hover-border: #1e8961;
@btn-success-hover-color: #1e8961;
@color-green: #00E466;
@color-orange: #ff5a00;
@color-red: #e30613;
@highlight-color: @primary-hover-color;
@link-color: #337ab7;
@link-hover-color: #000;
@nav-background-hover: #0000ff;
@report-background: #fff;
@report-color: #000;
@row-error-background: #FFCDD2;
@row-error-hover-background: #cd9b9e;
@row-highlight-background: #d9edf7;
@row-highlight-color: #575756;
@row-informal-background: #BBDEFB;
@row-informal-hover-background: #93b0cd;
@row-warning-background: #FFF9C4;
@row-warning-hover-background: #cdc798;
@scrollbar-background: #f5f5f5; //#cdcdcd;
@scrollbar-color: @form-control-disabled-color;
@select-arrow: "/assets/ci/cyberhub-light/images/arrow-down.png";
@logo-mini: "/assets/ci/cyberhub-light/images/cyberHub_logo_mini.svg";
@tabs-background: #fff;
@tabs-border-color: #e7eaec;
@tabs-color-hover: #000;
@tooltip-background: #00ff00;
@tooltip-color: #ff0000;
@progress-background: @main-background;

.mini-navbar .nav-header {
    background-image: url(@logo-mini) !important; /*important because inspinia overrides*/
}

body {
    background: @main-background;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: @main-foreground;
}

a {
    color: @link-color;
    -webkit-user-drag: none;
}

a:hover {
    color: @link-hover-color;
}

hr {
    border-top: @hr-border !important;
    width: 100%;
}

input {
    max-width: 100%;
}

input[type="checkbox"] {
    cursor: pointer;
}

input[type="number"],
input[type="text"] {
    background-color: @form-control-background;
    border: 1px solid @form-control-border-color;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type="number"]:focus,
input[type="text"]:focus {
    border: 1px solid @form-control-focus-border !important;
    outline: none;
}

input[type="range"] {
    display: inline-block;
    -webkit-appearance: none;
    background-color: transparent;
    min-width: 50px;
    cursor: w-resize;
}

input[type="range"][orient="vertical"] {
    cursor: n-resize;
    writing-mode: bt-lr;
    height: 50px;
}

/*Chrome*/
input[type="range"][orient="vertical"] {
    -webkit-appearance: slider-vertical;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: @white-color;
    height: 16px;
    width: 8px;
    margin-top: -7px;
}

input[type="range"][orient="vertical"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: @white-color;
    height: 8px;
    width: 16px;
    margin-top: 0px;
    margin-left: -10px;
}

input[type="range"]::-webkit-slider-runnable-track {
    background-color: @main-background;
    height: 2px;
    border-left: 2px solid @white-color;
    border-right: 2px solid @white-color;
}

input[type="range"][orient="vertical"]::-webkit-slider-runnable-track {
    width: 1px;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
}

/*FF*/
input[type="range"]::-moz-range-thumb {
    background-color: @white-color;
    height: 16px;
    width: 8px;
}

input[type="range"][orient="vertical"]::-moz-range-thumb {
    height: 8px;
    width: 16px;
}

input[type="range"]::-moz-range-track {
    height: 2px;
    background-color: @scrollbar-background;
}

input[type="range"][orient="vertical"]::-moz-range-track {
    width: 2px;
    height: 100%;
}

/*IE*/
input[type="range"]::-ms-tooltip {
    display: none;
}

input[type="range"]::-ms-track {
    height: 2px;
    background: transparent;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
}

input[type="range"][orient="vertical"]::-ms-track {
    width: 2px;
    height: 50px;
    background: transparent;
    border-top: 0;
    border-bottom: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
}

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
    background: @main-background;
}

input[type="range"]::-ms-thumb {
    background: @white-color;
    height: 16px;
    width: 8px;
}

input[type="range"][orient="vertical"]::-ms-thumb {
    height: 8px;
    width: 16px;
}

/*label[for]{ //removed because of feedback from MA
    cursor: pointer;
}*/

select {
    cursor: pointer;
}

select::-ms-expand { /* IE-caret fix */
    display: none;
}

select:not(:root:root) { /* safari */
    padding-left: 5px;
}

select:not([multiple]) {
    background-color: @form-control-background;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 2px 18px 2px 5px; /*needed to prevent overlapping*/
    background-image: url(@select-arrow) !important; /*important because inspinia overrides*/
    background-repeat: no-repeat;
    background-position: calc(~"100% - 4px") 50%;
    max-width: 100%;
}

::selection { //marked text highlighting (lmb + drag to mark) //ToDo: bind to separate variables(?)
    background-color: @primary-background;
    color: @primary-color;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0;
    background: #fff;
    width: 100%;
    padding: 15px;
    height: 100%;
}

.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.error {
    color: white;
    background-color: red;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 497px;
    //background: url("../images/hipster.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (   min--moz-device-pixel-ratio: 2), only screen and (     -o-min-device-pixel-ratio: 2/1), only screen and (        min-device-pixel-ratio: 2), only screen and (                min-resolution: 192dpi), only screen and (                min-resolution: 2dppx) {
    .hipster {
        //background: url("../images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

.hand {
    cursor: pointer;
}

#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

.alert .popover pre {
    font-size: 10px;
}

.voffset {
    margin-top: 2px;
}

.voffset1 {
    margin-top: 5px;
}

.voffset2 {
    margin-top: 10px;
}

.voffset3 {
    margin-top: 15px;
}

.voffset4 {
    margin-top: 30px;
}

.voffset5 {
    margin-top: 40px;
}

.voffset6 {
    margin-top: 60px;
}

.voffset7 {
    margin-top: 80px;
}

.voffset8 {
    margin-top: 100px;
}

.voffset9 {
    margin-top: 150px;
}

/* start Password strength bar style */
ul#strength {
    display: inline;
    list-style: none;
    margin: 0 0 0 15px;
    padding: 0;
    vertical-align: 2px;
}

.point:last {
    margin: 0 !important;
}

.point {
    background: #DDD;
    border-radius: 2px;
    display: inline-block;
    height: 5px;
    margin-right: 1px;
    width: 20px;
}

/* end Password strength bar style */

.top-0 {
    margin-top: 0;
}

.top-5 {
    margin-top: 5px;
}

.top-10 {
    margin-top: 10px;
}

.top-20 {
    margin-top: 20px;
}

.bottom-0 {
    margin-bottom: 0;
}

.bottom-5 {
    margin-bottom: 5px;
}

.bottom-10 {
    margin-bottom: 10px;
}

.bottom-20 {
    margin-bottom: 20px;
}

.p-r-0 {
    padding-right: 0 !important;
}

.full-width {
    width: 100%;
}

.mini-width {
    width: 1%; //this is a hack, but helps to give the minimal with possible. great for td-elements with centered icons, etc.
}

.text-green {
    color: @color-green;
}

.text-big {
    font-size: 24px;
}

.text-huge {
    font-size: 36px;
}

.text-titanic {
    font-size: 64px;
}

.text-orange {
    color: @color-orange;
}

.text-red {
    color: @color-red;
}

.text-vertical {
    writing-mode: vertical-lr;
    margin-left: calc(~"50% - 8px"); //this might need to be adjusted or applied to specific elements, if class is used more frequently.
}

label.disabled {
    color: darkgrey;
}

.uppercase {
    text-transform: uppercase;
}

.tree-selected {
    color: @highlight-color; //#1ab394;
    font-weight: bold;
}

#content {
    padding-top: 14px;
}

h2 {
    margin-bottom: 20px;
}

.form-image {
    border: 1px solid #ddd;
}

/* Fix chosen bug with width: 0px (https://github.com/harvesthq/chosen/issues/1548) */
.chosen-container {
    min-width: 100%; //!important;
    max-width: 100%;
}

/* Animate glyphicon loading */
.glyphicon-refresh-animate {
    animation: spin .7s infinite linear;
    -ms-animation: spin .7s infinite linear;
    -webkit-animation: spin .7s infinite linear;
    -moz-animation: spin .7s infinite linear;
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}

@-webkit-keyframes spinw {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

@-moz-keyframes spinm {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}

/* A scrollable box */
.scroll-box {
    height: 300px;
}

/* White modal dialogs */
.app-modal-window .modal-dialog {
    float: right;
    min-width: 600px;
    min-height: 300px;
    background-color: @modal-background;
    border-top: @modal-border-top;
    /*-webkit-box-shadow: 0px 0px 5px 5px rgba(255,255,255,0.1);*/
    /*-moz-box-shadow: 0px 0px 5px 5px rgba(255,255,255,0.1);*/
    /*box-shadow: 0px 0px 5px 5px rgba(255,255,255,0.1);*/
}

.modal-content {
    background-color: @modal-background !important;
}

.modal-dialog .modal-content {
    box-shadow: none;
}

.modal.animated {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    z-index: 2041 !important;
}

.green {
    color: @color-green;
}

.red {
    color: @color-red;
}

.yellow {
    color: #fff8ad;
}

.blue {
    color: #0000cc;
}

.row-error {
    //background-color: #FFCDD2;
    background-color: @row-error-background;
}

.row-error:hover {
    //background-color: #cd9b9e !important;
    background-color: @row-error-hover-background;
}

.row-warning {
    //background-color: #FFF9C4;
    background-color: @row-warning-background;
}

.row-warning:hover {
    //background-color: #cdc798 !important;
    background-color: @row-warning-hover-background;
}

.row-informal {
    //background-color: #BBDEFB;
    background-color: @row-informal-background;
}

.row-informal:hover {
    //background-color: #93b0cd !important;
    background-color: @row-informal-hover-background;
}

.progress {
    background-color: @progress-background;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.progress-bar {
    background-color: @primary-background;
}

.progress-bar-text {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 1px;
    text-align: center;
    font-weight: bold;
    color: @primary-color;
    text-shadow: 0px 0px 10px black;
}

/** Background if trial has ended. */
.trial-ended {
    background-color: #4D0300 !important; /* #FF621A */
}

.trial-ended-footer {
    position: absolute;
    z-index: 99999;
    bottom: 10px;
    color: #000951;
    font-weight: bold;
    text-align: center;
    width: 100%;
}

/** Normal text in sidebar. */
.nav-text {
    color: @nav-header-color;
}

.table > thead > th,
.table > thead > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    vertical-align: middle;
}

#roomOverviewTable > tbody > tr > td {
    vertical-align: top !important;
}

.table-striped > tbody > tr:nth-of-type(odd):not(.bg-info) {
    background-color: @table-striped-background;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: @form-control-disabled-background;
    color: @form-control-disabled-color;
    opacity: 1;
}

.form-group-sm .form-control,
.form-group-sm .form-control-static {
    font-size: 13px; //fixes text-size in devices-panel
}

.form-control-static {
    overflow-wrap: break-word;
}

.table-hover > tbody > tr:hover {
    background-color: @table-hover-background;
    color: @table-hover-color;
}

.table tbody td:first-child { //fix for bad line breaks on alarms and co
    min-width: 55px;
}

.table td:last-child.text-right {
    white-space: nowrap;
}

.table-responsive {
    .chosen-container {
        //position: initial;
        position: relative;
    }

    .chosen-container.chosen-container-active {
        position: relative;
    }
}

.chosen-container-single, .chosen-container, .chosen-single, .chosen-multi {
    background-color: @form-control-background !important;
    border: @form-control-border !important;
    border-radius: 0;
    //border: @form-control-border !important;
}

.chosen-container-multi {
    min-height: 34px;
}

.chosen-container-multi .chosen-choices {
    padding-top: 2px;
}

.chosen-container > a,
.chosen-single > a,
.chosen-multi > a {
    border: none;
}

.chosen-container-single .chosen-single {
    border: none !important;
}

.chosen-container-active {
    border: 1px solid @primary-border !important;
}

.chosen-container-active.chosen-with-drop .chosen-single {
    border: none !important;
    background: inherit !important;
    box-shadow: none !important; //removing weird chosen 1px top border in some drop-downs
}

.chosen-single span {
    color: @form-control-color;
}

.chosen-container .chosen-drop {
    border: @form-control-border !important;
    background: @form-control-background !important;
}

.chosen-container-multi .chosen-choices {
    background: @form-control-background !important;
    border: none; //override to prevent chosen-multi's thick border
}

.chosen-container-multi .chosen-choices li.search-choice {
    color: @default-color;
    background: @default-background;
    border-color: @default-border;
}

.chosen-container-multi .chosen-drop .result-selected {
    color: @form-control-disabled-color;
}

.chosen-container .chosen-results li.highlighted {
    background-color: @form-control-highlighted-background !important;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, @form-control-highlighted-gradient-start), color-stop(90%, @form-control-highlighted-gradient-end)) !important;
    background-image: -webkit-linear-gradient(@form-control-highlighted-gradient-start 20%, @form-control-highlighted-gradient-end 90%) !important;
    background-image: -moz-linear-gradient(@form-control-highlighted-gradient-start 20%, @form-control-highlighted-gradient-end 90%) !important;
    background-image: -o-linear-gradient(@form-control-highlighted-gradient-start 20%, @form-control-highlighted-gradient-end 90%) !important;
    background-image: linear-gradient(@form-control-highlighted-gradient-start 20%, @form-control-highlighted-gradient-end 90%) !important;
    color: @form-control-highlighted-color !important;
}

.fa-border {
    border-color: @form-control-border-color !important;
}

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
    background-color: @table-hover-background !important;
    color: @table-hover-color;
}

.logo-insert {
    width: @logo-width;
    height: @logo-height;
    pointer-events: none; //this should fix issue of difficult to click button / breadcrumbs
}

.ibox-tools a.btn-create {
    color: @btn-create-color;
}

.ibox-tools a.btn-primary {
    color: @primary-color;
}

.ibox-tools a.btn-primary:hover {
    color: @primary-background !important;
}

.radio label::before {
    border: @form-control-border !important;
}

.radio-primary input[type="radio"]:checked + label::before {
    background-color: @content-background !important;
}

.radio-primary input[type="radio"]:checked + label::after {
    background-color: @primary-background !important;
}

.login-background {
    background: @login-background;
    background-color: @login-background-color;
    background-size: cover !important;
    height: 100% !important;
    width: 100% !important;
    position: fixed;
    overflow: auto;
}

.logo-login {
    width: 100%;
}

.blankPages {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.license-warning {
    max-width: 1500px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

//.logo-name::before {
//content: @product-logo;
//}

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
    background-color: @primary-background !important;
    border-color: @primary-background !important;
}

.logo-headline::before {
    content: @logo-headline;
}

.copy-color {
    color: @copy-color;
    font-weight: bold;
}

.breadcrumb {
    border-radius: 0 !important;
}

.breadcrumb {
    border-bottom: none !important;
    li.active {
        span {
            font-weight: bold;
        }
    }
    padding-top: 20px;
    padding-left: 20px;
}

.navbar-fixed-top, .navbar-static-top {
    background-color: @nav-background;
}

.nav-headline::after {
    content: @nav-headline;
}

.borderless {
    border: none !important;
}

.mark-personal-template:before {
    content: '(Personal) ';
}

.mark-group-template:before {
    content: '(Group) ';
}

.mark-system-template:before {
    content: '(System) ';
}

.v-middle {
    vertical-align: middle !important;
}

.cell-highlight > * {
    box-shadow: 0 0 5px 1px @danger-background;
}

//.navbar-top-links > li::after {
//    content: "|";
//    display: inline-block;
//}

/* Scrollbar-fixes */
/* Chrome */
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: @scrollbar-background;
}

::-webkit-scrollbar-thumb {
    background: @scrollbar-color;
}

/* IE */
* {
    scrollbar-face-color: @scrollbar-color;
    scrollbar-track-color: @scrollbar-background;
}

/* Skin for the color picker */
.sp-replacer,
.sp-replacer:hover { //select-box
    background: @form-control-background; //#fff !important;
    border: solid 1px @form-control-border-color; //#E5E6E7 !important;
    color: inherit;
}

.sp-active {
    //border-color: #1ab394 !important;
    border-color: @form-control-focus-border;
    .sp-dd {
        color: @main-foreground;
    }
}

.sp-container { //color picker itself
    background-color: @content-background;
    border: none !important;
    //background-color: @form-control-background; //#fff !important;
    //border: solid 1px #E5E6E7 !important;
    .drop-shadow;
}

.sp-input {
    color: @form-control-color;
    border: 1px solid @form-control-border-color !important;
    border-radius: 0 !important;
    :focus {
        border-color: @form-control-focus-border !important;
    }
}

.sp-picker-container {
    border: none;
}

.sp-container button {
    background: #ffffff;
    border: 1px solid #e7eaec;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    border-radius: 4px;
}

.sp-preview { //select's color area
    max-width: 50%; //prevent linebreak inside select
}

.fullscreen-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(33, 33, 33, 1);
    display: table;
}

.widget-border {
    border: #e7eaec 1px solid;
}

.page-portrait {
    height: 1370px;
    min-height: 1370px;
    max-height: 1370px;
    display: block;
    overflow: hidden;
}

.page-landscape {
    height: 910px;
    min-height: 910px;
    max-height: 910px;
    display: block;
    overflow: hidden;
}

.report-preview .ibox-content {
    border: none;
}

.report-preview .leaflet-control-container {
    visibility: hidden;
    display: none;
}

.leaflet-touch .leaflet-bar {
    border: none; //removing thick outline
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    font-size: 18px !important;
    text-align: center;
    width: 34px !important;
    color: @white-color !important;
    border: 1px solid @white-border !important;
    background-color: @white-background !important;
}

.leaflet-control-zoom-out {
    border-top: none !important; //removing to prevent doubled border where zoom-in and zoom-out meet
}

.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
    color: @white-hover-color !important;
    background-color: @white-hover-background !important;
    border-color: @white-hover-border !important;
}

.btn-download,
.btn-download:focus:not([disabled]) {
    color: @btn-download-color;
    background-color: @btn-download-background;
    border: 1px solid @btn-download-border;
}

.btn-download:hover:not([disabled]) {
    color: @btn-download-hover-color;
    background-color: @btn-download-hover-background;
    border-color: @btn-download-hover-border;
}

.btn-back,
.btn-back:focus:not([disabled]) {
    color: @btn-back-color;
    background-color: @btn-back-background;
    border: 1px solid @btn-back-border;
}

.btn-back:hover:not([disabled]) {
    color: @btn-back-hover-color;
    background-color: @btn-back-hover-background;
    border-color: @btn-back-hover-border;
}

.btn-cancel,
.btn-cancel:focus:not([disabled]) {
    color: @btn-cancel-color;
    background-color: @btn-cancel-background;
    border: 1px solid @btn-cancel-border;
}

.btn-cancel:hover:not([disabled]) {
    color: @btn-cancel-hover-color;
    background-color: @btn-cancel-hover-background;
    border-color: @btn-cancel-hover-border;
}

.btn-edit,
.btn-edit:focus:not([disabled]) {
    color: @btn-edit-color;
    background-color: @btn-edit-background;
    border: 1px solid @btn-edit-border;
}

.btn-edit:hover:not([disabled]) {
    color: @btn-edit-hover-color;
    background-color: @btn-edit-hover-background;
    border-color: @btn-edit-hover-border;
}

.btn-default[disabled]:hover {
    color: @default-color;
}

.btn-delete,
.btn-delete:focus:not([disabled]) {
    color: @btn-delete-color;
    background-color: @btn-delete-background;
    border: 1px solid @btn-delete-border;
}

.btn-delete:hover:not([disabled]) {
    color: @btn-delete-hover-color !important;
    background-color: @btn-delete-hover-background;
    border-color: @btn-delete-hover-border;
}

.btn-delete[disabled],
.btn-delete[disabled]:hover {
    color: @btn-delete-color !important;
}

.btn-move,
.btn-move:focus:not([disabled]) {
    color: @btn-move-color;
    background-color: @btn-move-background;
    border: 1px solid @btn-move-border;
}

.btn-move:hover:not([disabled]) {
    color: @btn-move-hover-color;
    background-color: @btn-move-hover-background;
    border-color: @btn-move-hover-border;
}

.btn-create,
.btn-create:focus:not([disabled]) {
    color: @btn-create-color;
    background-color: @btn-create-background;
    border: 1px solid @btn-create-border;
}

.btn-create:hover:not([disabled]) {
    color: @btn-create-hover-color;
    background-color: @btn-create-hover-background;
    border-color: @btn-create-hover-border;
}

.btn-create[disabled]:hover {
    color: @btn-create-color !important;
}

.btn-details,
.btn-details:focus:not([disabled]) {
    color: @btn-details-color;
    background-color: @btn-details-background;
    border: 1px solid @btn-details-border;
}

.btn-details:hover:not([disabled]) {
    color: @btn-details-hover-color;
    background-color: @btn-details-hover-background;
    border-color: @btn-details-hover-border;
}

.btn-details[disabled]:hover {
    color: @btn-details-color;
}

.btn-view,
.btn-view:focus:not([disabled]) {
    color: @btn-view-color;
    background-color: @btn-view-background;
    border: 1px solid @btn-view-border;
}

.btn-view:hover:not([disabled]) {
    color: @btn-view-hover-color;
    background-color: @btn-view-hover-background;
    border-color: @btn-view-hover-border;
}

.btn-remove,
.btn-remove:focus:not([disabled]) {
    color: @btn-remove-color;
    background-color: @btn-remove-background;
    border: 1px solid @btn-remove-border;
}

.btn-remove:hover:not([disabled]) {
    color: @btn-remove-hover-color;
    background-color: @btn-remove-hover-background;
    border-color: @btn-remove-hover-border;
}

.btn-apply,
.btn-apply:focus:not([disabled]) {
    color: @btn-apply-color;
    background-color: @btn-apply-background;
    border: 1px solid @btn-apply-border;
}

.btn-apply:hover:not([disabled]) {
    color: @btn-apply-hover-color;
    background-color: @btn-apply-hover-background;
    border-color: @btn-apply-hover-border;
}

.btn-apply[disabled]:hover {
    color: @btn-apply-color !important;
}

.btn-add {
    color: @btn-add-color;
    background-color: @btn-add-background;
    border: 1px solid @btn-add-border;
}

.btn-add:focus:not([disabled]) {
    color: @btn-add-color;
    background-color: @btn-add-background;
    border-color: @btn-add-border;
}

.btn-add:hover:not([disabled]) {
    color: @btn-add-hover-color;
    background-color: @btn-add-hover-background;
    border-color: @btn-add-hover-border;
}

.btn-primary:focus:not([disabled]) {
    color: @primary-color;
    background-color: @primary-background;
    border-color: @primary-border;
}

.btn-primary:hover:not([disabled]) {
    color: @primary-hover-color;
    background-color: @primary-hover-background;
    border-color: @primary-hover-border;
}

.btn-save {
    color: @btn-save-color;
    background-color: @btn-save-background;
    border: 1px solid @btn-save-border;
}

.btn-save:focus:not([disabled]) {
    color: @btn-save-color;
    background-color: @btn-save-background;
    border-color: @btn-save-border;
}

.btn-save:hover:not([disabled]) {
    color: @btn-save-hover-color;
    background-color: @btn-save-hover-background;
    border-color: @btn-save-hover-border;
}

.btn-save[disabled]:hover,
.btn-add[disabled]:hover {
    color: @btn-save-color;
}

.btn-success,
.btn-success[disabled],
.btn-success[disabled]:hover {
    color: @btn-success-color;
    background-color: @btn-success-background;
    border-color: @btn-success-border;
}

.btn-success:focus:not([disabled]) {
    color: @btn-success-color;
    background-color: @btn-success-background;
    border-color: @btn-success-border;
}

.btn-success:hover:not([disabled]) {
    color: @btn-success-hover-color;
    background-color: @btn-success-hover-background;
    border-color: @btn-success-hover-border;
}

.btn-close,
.btn-close:focus:not([disabled]) {
    color: @btn-close-color;
    background-color: @btn-close-background;
    border: 1px solid @btn-close-border;
}

.btn-close:hover:not([disabled]) {
    color: @btn-close-hover-color;
    background-color: @btn-close-hover-background;
    border-color: @btn-close-hover-border;
}

.btn-white,
.btn-white:focus:not([disabled]) {
    //color: @white-color;
    //background-color: @white-background;
    border: 1px solid @white-border !important;
}

.btn-white:hover:not([disabled]) {
    border-color: @white-hover-border !important;
}

.btn-controlround{
    text-align: center;
    min-width: 75px;
    max-width: 170px;
    min-height: 75px;
    max-height: 170px;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.btn-controlround span {
    display:inline-block;
    vertical-align:middle;
    max-width:90%;
}

select.sel-btn-sm {
    display: inline-block;
    width: calc(~"100% - 40px");
}

.close {
    color: @main-foreground;
    opacity: 0.5;
}

.close:hover {
    color: @main-foreground;
    opacity: 1.0;
}

// DL: additions (placed here for now, file should be structured and organized later on)
.ibox-tools .checkbox {
    margin-top: 0;
}

.nav > li > a i {
    font-size: 20px
}

// DL: additions (placed here for now, file should be structured and organized later on)
.ibox-tools > .checkbox {
    margin-top: 0;
}

.nav > li > a i {
    font-size: 20px
}

.tooltip-inner {
    color: @tooltip-color;
    background-color: @tooltip-background !important;
    border: 1px solid @tooltip-background;
    max-width: 250px;
}

.tooltip-inner, .tooltip.in {
    opacity: 0.95;
    filter: alpha(opacity=95);
}

.tooltip.top .tooltip-arrow {
    border-top-color: @tooltip-background !important;
}

.tooltip.right .tooltip-arrow {
    border-right-color: @tooltip-background !important;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: @tooltip-background !important;
}

.tooltip.left .tooltip-arrow {
    border-left-color: @tooltip-background !important;
}

.drop-shadow {
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
}

.badge-danger, .label-danger {
    background-color: @badge-danger-background !important;
}

.badge-warning {
    background-color: @badge-warning-background !important;
    color: black !important;
}

.badge-info {
    background-color: rgb(0, 101, 152) !important;
}
.badge-historical {
    background-color: rgb(169, 169, 169) !important;
}

.badge-custom {
    color: #5e5e5e !important;
    border-color: #5e5e5e !important;
    background-color: rgb(229, 229, 229) !important;
}

.badge-pulse {
    animation-name:badge-pulse;
    animation-duration: 0.3s;
    animation-delay: 0s;
    animation-iteration-count:infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
}

@keyframes badge-pulse {
    from { transform: scale(1); background: @badge-danger-background;}
    to { transform: scale(1.3); background: #ff0000;}
}

//.navbar-default div div > .nav > li > a:hover { // only mainchapters (ones with icons) are highlighted
.navbar-default .nav > li > a:hover { // all chapters and subchapters are highlighted
    background: @nav-background-hover !important;
    background-color: @nav-background-hover !important;
}

.navbar-default .nav .arrow {
    padding-top: 7px;
}

.webglContainer {
    width: 100%;
    height: 100%;
    display: inline-block;
}

.roomEditorSideBar {
    width: calc(30%);
    height: calc(~"88% - 130px"); //95%;
    background-color: 'rgba(0,0,0,0)';
    pointer-events: none;
    display: inline-block;
    position: absolute;
    top: 130px; //calc((100% - 95%) / 2);
    left: 69%; //calc(100% - 30%);
}

/* MK SDS Animation etc */
.transitionSidebar {
    width: 50px; //10%;
    height: 100%;
    background-color: @content-background; //#fff;
    float: right;
    display: inline-block;
    transition-property: width;
    -webkit-transform: width 1s;
    transition: width 1s;
    pointer-events: auto;
}

.editorFullScreen {
    position: fixed;
    top: 5px;
    left: 5px;
    bottom: 5px;
    right: 5px;
    border: 1px solid #222;
    z-index: 2001;
}

.editorFullScreen > .ibox-content {
    height: calc(~"100% - 32px");
}

.editorFullScreen .glOverlayContainer {
    top: 1%;
}

/*overriding style:*/
.onoffswitch-label {
    border-color: @primary-background;
}

.onoffswitch-switch {
    border-color: @primary-background;
    background: @primary-color;
}

.onoffswitch-inner:before {
    background-color: @primary-background;
    color: @primary-color;
}

.onoffswitch-inner:after {
    background-color: @primary-hover-background;
    color: @primary-hover-color;
}

.onoffswitch.roomdevicesswitch {
    width: 74px !important;
}

.roomdevicesswitch .onoffswitch-switch {
    right: 56px !important;
}

.roomdevicesswitch .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px !important;
}

#toolButtonsMin button,
#toolButtonsMax button {
    font-size: 16px;
}

#toolButtonsMin button,
#partlibButtonsMin button {
    margin: 1px auto 1px calc(~"50% - 22px");
}

#toolButtonsMin button[disabled],
#toolButtonsMax button[disabled] {
    background-color: @form-control-disabled-background;
    color: @form-control-disabled-color;
}

#toolButtonsMax button,
#toolSettings button {
    margin-bottom: 4px;
}

.btn-browse {
    margin: 3px;
    margin-left: calc(~"50% - 12px");
}

.separator {
    height: 1px;
    width: 100%;
    background-color: @default-border;
    margin: 5px 0px;
}

#scrollWrapper {
    //TODO: find fix for height issues
    overflow: hidden;
}

.scrollable {
    overflow: auto;
}

.scrollable-y {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    height: 100%;
}

//[ng-show="ctrl.detail.selectedUsedParam !== null"] .nav-tabs>li.active>a,
//[ng-show="ctrl.detail.selectedUsedParam !== null"] .nav-tabs>li.active>a:focus, //roomeditor sidebars datapoint override.
//[ng-show="ctrl.detail.selectedUsedParam !== null"] .nav-tabs>li.active>a:hover,
.ibox-content .nav-tabs > li.active > a,
.ibox-content .nav-tabs > li.active > a:focus,
.ibox-content .nav-tabs > li.active > a:hover,
.tabs-container .nav-tabs > li.active > a,
.tabs-container .nav-tabs > li.active > a:focus,
.tabs-container .nav-tabs > li.active > a:hover { //overriding inspinia
    background-color: @tabs-background !important;
    color: @tabs-color-hover;
    border-color: @tabs-border-color @tabs-border-color rgba(0, 0, 0, 0) @tabs-border-color !important;
}

[ng-show="ctrl.detail.selectedUsedParam !== null"] .panel-body,
.ibox-content .panel-body,
.tabs-container .panel-body { //overriding inspinia
    background: @tabs-background !important;
    border-color: @tabs-border-color !important;
}

[ng-show="ctrl.detail.selectedUsedParam !== null"] .nav-tabs,
.ibox-content .nav-tabs,
.tabs-container .nav-tabs { //overriding inspinia
    border-color: @tabs-border-color !important;
}

[ng-show="ctrl.detail.selectedUsedParam !== null"] .nav-tabs > li > a:hover,
.ibox-content .nav-tabs > li > a:hover,
.tabs-container .nav-tabs > li > a:hover {
    color: @tabs-color-hover;
    background: @tabs-background;
    border-color: @tabs-border-color @tabs-border-color rgba(0, 0, 0, 0) @tabs-border-color !important;
}

.list-group-item {
    border-color: @tabs-border-color !important; //overriding inspinia // TODO: might need to become an own variable
}

a.list-group-item {
    color: @main-foreground;
}

a.list-group-item:hover {
    color: @main-foreground;
    background: @table-hover-background;
}

a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
    background-color: @primary-background;
    color: @primary-color;
}

.panel-default {
    border-color: @tabs-border-color;
}

.panel-default > .panel-heading {
    background: @main-background;
    //border-color: @tabs-border-color;
    color: @main-foreground;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: transparent; //should fix white line noticeable in dark UIs
}

.daterangepicker {
    background-color: @content-background;
}

.daterangepicker:before {
    border-bottom: 7px solid rgba(86, 96, 117, 0.7); //arrow's outline //TODO: bind to variables!
}

.daterangepicker:after {
    border-bottom: 6px solid @content-background !important;
}

.daterangepicker td.off {
    background-color: transparent;
    color: @form-control-disabled-color;
}

.calendar .calendar-table {
    background-color: transparent;
}

.calendar-table > .table-condensed {
    border-collapse: separate; //needed for full border around hovered dates
}

.calendar-table td.active,
.calendar-table td.active:hover { //selected date
    background-color: @primary-background;
    color: @primary-color;
}

.calendar-table td.available:hover:not(.active) {
    background-color: @primary-hover-background;
    color: @primary-hover-color;
    border-color: @primary-hover-border;
}

.calendar-table th.next:hover,
.calendar-table th.prev:hover {
    background-color: @default-hover-background !important;
    color: @default-hover-color !important;
}

input.overlayInput {
    color: @form-control-color;
    background-color: @form-control-background;
    border: @form-control-border;
    width: 100%;
    text-align: center;
}

/*----------------------------------*/
/*-------------- dPad --------------*/
/*----------------------------------*/
div[class^="dPad"] {
    transition: color 0.4s, background-color 0.4s, background 0.4s;
    color: @default-color;
}

div[class^="dPad"]:not(.dPad):not(.dPadSubL):not(.dPadSubR),
.alignContainer,
.dPadCamRaise,
.dPadCamSink {
    position: absolute;
    pointer-events: auto;
}

div[class^="dPad"]:not(.dPad):not(.dPadSubL):not(.dPadSubR) > span,
.alignContainer > span,
.dPadCamRaise > span,
.dPadCamSink > span {
    cursor: pointer;
}

.dPad {
    opacity: 0.95;
    height: 100px;
    width: 100px;
    pointer-events: auto;
    background-image: radial-gradient(circle 30px at 0 0, rgba(0, 0, 0, 0) 29px, @default-background 30px, @default-background 70px, rgba(0, 0, 0, 0) 71px), radial-gradient(circle 30px at 100px 0, rgba(0, 0, 0, 0) 29px, @default-background 30px, @default-background 70px, rgba(0, 0, 0, 0) 71px), radial-gradient(circle 30px at 0 100px, rgba(0, 0, 0, 0) 29px, @default-background 30px, @default-background 70px, rgba(0, 0, 0, 0) 71px), radial-gradient(circle 30px at 100px 100px, rgba(0, 0, 0, 0) 29px, @default-background 30px, @default-background 70px, rgba(0, 0, 0, 0) 71px);
    border-radius: 50%;
    font-size: 20px;
    margin: 10px 0 0 10px;
}

.dPad:not(:root:root) {
    /* safari-fix */
    background-image: radial-gradient(circle 30px at 0 0, rgba(0, 0, 0, 0) 30px, @default-background 30px, @default-background 70px, rgba(0, 0, 0, 0) 70.001px), radial-gradient(circle 30px at 100px 0, rgba(0, 0, 0, 0) 30px, @default-background 30px, @default-background 70px, rgba(0, 0, 0, 0) 70.001px), radial-gradient(circle 30px at 0 100px, rgba(0, 0, 0, 0) 30px, @default-background 30px, @default-background 70px, rgba(0, 0, 0, 0) 70.001px), radial-gradient(circle 30px at 100px 100px, rgba(0, 0, 0, 0) 30px, @default-background 30px, @default-background 70px, rgba(0, 0, 0, 0) 70.001px);
}

@-moz-document url-prefix() {
    .dPad {
        background-image: radial-gradient(circle 30px at 0 0, rgba(0, 0, 0, 0) 29px, @default-background 30px, @default-background 70px, rgba(0, 0, 0, 0) 70px), radial-gradient(circle 30px at 100px 0, rgba(0, 0, 0, 0) 29px, @default-background 30px, @default-background 70px, rgba(0, 0, 0, 0) 70px), radial-gradient(circle 30px at 0 100px, rgba(0, 0, 0, 0) 29px, @default-background 30px, @default-background 70px, rgba(0, 0, 0, 0) 70px), radial-gradient(circle 30px at 100px 100px, rgba(0, 0, 0, 0) 29px, @default-background 30px, @default-background 70px, rgba(0, 0, 0, 0) 70px);
    }
}

.dPadLeft {
    top: 50px;
    left: 20px;
}

.dPadRight {
    top: 50px;
    left: 80px;
}

.dPadUp {
    top: 20px;
    left: 50px;
}

.dPadDown {
    top: 80px;
    left: 50px;
}

.dPadCenter {
    top: 44px;
    /*45*/
    left: 43px;
    /*45*/
    font-size: 24px;
}

div[class^="dPadButton"] {
    opacity: 0.95;
    background-color: @default-background;
    padding: 5px;
    border-radius: 100%;
    //cursor: pointer;
    z-index: 2;
    transition: color 0.4s, background-color 0.4s, background 0.4s;
}

div[class^="dPadButton"] > span {
    cursor: pointer;
}

.dPadButton1 {
    top: 10px;
    left: 130px;
}

.dPadButton2 {
    top: 45px;
    left: 137px;
}

.dPadButton3 {
    top: 80px;
    left: 130px;
}

div.dPadButton31 {
    top: 80px;
    left: 156px;
    /*163px;*/
    font-size: 10px;
    height: 30px;
    border-radius: 0 15px 15px 0;
    background: radial-gradient(circle at -10px 15px, transparent 16px, @default-background 16px);
    background-color: transparent;
}

.dPadButton31 span {
    margin: 3px -3px 0 2px;
}

.dPadButton4 {
    top: 112px;
    left: 112px;
}

.dPadButton5 {
    top: 3px;
    left: 3px;
}

.dPadCamRaise {
    top: 10px;
    left: 103px;
    height: 49px;
    width: 35px;
    background: radial-gradient(circle at -40px 50px, rgba(0, 0, 0, 0) 50px, @default-background 51px, @default-background 71px, rgba(0, 0, 0, 0) 72px);
    border-radius: 5px;
}

.dPadCamRaise:not(:root:root) {
    /* safari */
    background: radial-gradient(circle at -40px 50px, rgba(0, 0, 0, 0) 50px, @default-background 50.001px, @default-background 72px, rgba(0, 0, 0, 0) 72.001px);
}

.dPadCamSink {
    top: 61px;
    left: 103px;
    height: 49px;
    width: 35px;
    background: radial-gradient(circle at -40px 0, rgba(0, 0, 0, 0) 50px, @default-background 51px, @default-background 71px, rgba(0, 0, 0, 0) 72px);
    border-radius: 5px;
}

.dPadCamSink:not(:root:root) {
    /* safari */
    background: radial-gradient(circle at -40px 0, rgba(0, 0, 0, 0) 50px, @default-background 50.001px, @default-background 72px, rgba(0, 0, 0, 0) 72.001px);
}

.dPadCamRaise span,
.dPadCamSink span {
    position: absolute;
    top: 11px;
    left: 7px;
}

.dPadCamRaise span {
    top: 20px;
}

.dPadSub {
    opacity: 0.95;
    position: absolute;
    top: 115px;
    left: 24px;
}

.dPadSub div {
    width: 35px;
    height: 20px;
    display: inline-block;
    background-color: @default-background;
    text-align: center;
    //border: none;
    border: 1px solid @default-background; //to prevent active sub-buttons to be indistinguishable from the background.
    margin: -1px;
    margin-top: 5px;
    position: static;
}

.dPadSub div > span {
    user-select: none;
}

.dPadSubL {
    cursor: pointer;
    border-radius: 5px 0 0 5px;
}

.dPadSubR {
    cursor: pointer;
    border-radius: 0 5px 5px 0;
}

.fakeSelect {
    color: #d2d2d2; //todo: bind to variable
    background: rgba(255, 255, 255, .1); //todo: bind to variable
    transition: color 0.4s, backgorund-color 0.4s, background 0.4s;
}

.glOverlayContainer {
    position: relative;
    top: 1%;
}

.glPanel {
    position: absolute;
    opacity: 0.95;
    padding: 10px;
    min-width: 200px;
    background: radial-gradient(circle 10px at -6px 3px, transparent 15px, @default-background 16px);
    pointer-events: auto;
    /*TODO: find workaround for cutout corner*/
    z-index: 2;
}

.glPanel select {
    background-color: @default-background;
}

.glPanel .checkbox {
    margin-top: 5px;
    margin-bottom: 5px;
}

#fullOverlayContainer { //contains the loading-text in roomeditor
    position: absolute;
    z-index: 1000;
    height: calc(~"100% - 95px");
    width: calc(~"100% - 30px");
    background-color: @main-background;
    h2 {
        margin-top: 25%;
    }
}

#glAlignContainer {
    position: absolute;
    top: -20px;
    left: 190px;
    white-space: nowrap;
}

#glDisplayPanel {
    left: 162px;
    top: 60px;
    min-width: 210px;
}

#glSensorOverlayConfPanel {
    top: 95px;
    left: 170px;
    min-width: 60px;
}

#glSettingsPanel {
    top: 16px;
    left: 25px;
    min-width: 210px;
}

#glSimuPanel {
    top: 127px;
    left: 136px;
    width: 210px; /*475px;*/
}

/* tree fixes: */
treecontrol {
    color: inherit;
}

.tree-branch-head {
    padding: 1px 5px;
}

.tree-full-line {
    display: inline-block;
    width: calc(~"100% - 20px");
    margin: 0 0 3px;
    padding: 0px 5px;
    white-space: nowrap;
}

.tree-full-line-noselect {
    display: inline-block;
    width: calc(~"100% - 20px");
    margin: 0 0 3px;
    padding: 0px 5px;
    white-space: nowrap;
}

treecontrol > ul > li { //adjustment for first tree-level only
    padding-left: 5px;
}

.tree-selected > .tree-full-line {
    background-color: @nav-background-highlight;
    color: @nav-text-color-highlight;
}

.tree-selected > .tree-full-line-noselect {
    background-color: @nav-background;
    color: @main-foreground;
    font-weight: normal;
}

.tree-full-line:hover {
    background-color: @nav-background-hover; //for testing
    color: @nav-text-color-highlight;
}

.btn-black { //blöder Name, den muss ich demnächst mal umbenennen.
    //background: #363737 !important;
    color: @btn-3d-active-color !important; //@btn-edit-hover-color !important;
    background-color: @btn-3d-active-background !important; //@btn-edit-hover-background !important;
    //border-color: @btn-edit-hover-border !important;
}

.uib-tab-heading > [class^="icon-"] {
    font-size: 20px;
}

.label-danger,
.label-default,
.label-primary {
    padding: 3px 6px;
    display: inline-block;
    margin: 2px;
}

.hulabel {
    position: absolute;
    min-width: 125px;
    min-height: 40px;
}

.icon-active {
    opacity: 1;
}

.icon-inactive {
    opacity: 0.3;
}

[ng-controller="IndexController"] {
    height: 100%; //test to remove
}

#wrapper {
    min-height: 100%;
    height: 100%;
    overflow-y: auto !important;
}

#wrapper #page-wrapper {
    min-height: 100% !important; //Temp
    height: 100%;
    //overflow-y:auto;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 10px;
}

// screen and (-webkit-device-pixel-ratio: 2),
// max-device-with: 480px
//screen and (pointer: coarse),
@media screen and (orientation: landscape) and (max-device-width: 75em) and (pointer: coarse), screen and (orientation: portrait) {
    // for tablets, smartphones, etc. to save space
    /* scrollbar fixes */
    ::-webkit-scrollbar {
        width: 2px;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    }

    .desktop-only {
        display: none !important;
    }

    .single-remain { //counter to desktop-only for positioning icons centered in buttons
        width: 100%;
        text-align: center;
    }

    #breadcrumb-container {
        max-width: calc(~"100% - 170px") !important; //important to override non mobile width. might not be needed anymore after CSS sort
    }
}

//experimental:
body.mini-navbar .profile-element { //overriding inspinia selector
    display: block;
}

body.fixed-sidebar.mini-navbar .navbar-default .nav li a span strong { //overriding inspinia selector
    display: block;
}

body.mini-navbar .nav-header { //overriding stupid inspinia rule
    padding: 25px;
    background-color: @nav-background;
}

.no-saved-filter {
    padding: 5px 10px;
}

.dashboard-fix {
    margin-right: -40px;
    margin-left: -40px;
}

.panel { //should solve the problems in roomeditors sidebar in the dark-theme.
    background-color: inherit;
}

.bg-info { //e.g. highlighting templates(rows) in analysis
    color: @row-highlight-color;
    background-color: @row-highlight-background;
}

.well {
    word-break: break-all;
}

select {
    border: 1px solid @form-control-border-color !important;
}

select:focus { //adjusting html select to ci
    box-shadow: 0 0 3px @primary-background;
    outline: none;
    border-color: @primary-background;
}

select:focus:not(:root:root) {
    box-shadow: none;
}

/* safari */
select:active:not(:root:root) {
    box-shadow: 0 0 3px @primary-background;
}

/* safari */
selecT:focus a {
    outline: none;
}

.navbar-right {
    float: right;
}

// overriding stupid navbar.less-media-query

.grid-stack-item-content {
    background-color: @content-background !important;
}

/*------------------------------------*/
/*---------- report-stuff ------------*/
/*------------------------------------*/
//:not(.leaflet-control-zoom):not(.leaflet-left)
[ng-repeat="page in rctrl.report.pages"] .ibox-content,
[ng-repeat="page in rctrl.report.pages"] .ibox-content *:not(.leaflet-marker-icon):not(.leaflet-marker-shadow):not(.leaflet-popup):not(.leaflet-popup-close-button):not(.leaflet-popup-tip-container):not(.glyphicon-exclamation-sign):not(.glyphicon-info-sign):not(.glyphicon-remove-sign) { //forcing all elements to given color, with some exceptions
    background-color: @report-background;
    color: @report-color;
}

[ng-repeat="page in rctrl.report.pages"] *.grid-stack-item-content {
    background-color: @report-background !important;
    //overflow:hidden !important; //this might be needed to get rid of scrollbars
}

[ng-repeat="page in rctrl.report.pages"] rect.highcharts-background { //script-drawn svg's background, i.e. analysis graphs
    fill: @report-background;
}

widgetlocationmap .leaflet-top.leaflet-left,
widgetlocationmap .leaflet-control-zoom {
    background-color: transparent !important; //fix for locationmap bug in reports
}

div.modal-header,
div.modal-footer { //bootstrap modal
    border-color: @content-border-color;
}

.has-error .help-block {
    color: @badge-danger-background;
}

#canvas { //fix for locations image-size on small devices
    max-width: 100%;
}

/*.childs-even > div:nth-child(even) {
    background-color: rgba(0,0,0,0.1);
}*/

.float-panel:nth-child(even) { // is float-panel just a misspelling?
    //background-color: rgba(0, 0, 0, 0.1);
}

.floor-panel:nth-child(even) {
    background-color: @table-striped-background;
}

.floor-panel:hover {
    background-color: @table-hover-background;
}

.room-panel {
    background-color: @content-background;
}

.room-panel:hover {
    color: @table-hover-color;
    box-shadow: 0 0 5px @primary-border; //not 100% sure about this one, but without the difference is sometimes to slightly
}

.grid-stack > .grid-stack-item > .grid-stack-item-content { //overriding gridstack.min.css to prevent unwanted scrollsbars in widgets i.e. weather-widget.
    overflow: hidden;
    left: 7px;
    right: 7px;
}

.widget-location-map {
    .leaflet-popup-content {
        min-width: 100px;
    }
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip { //adjusting map-location-panels to ci-colors
    color: @main-foreground;
    background: @content-background;
}

.ui-draggable {
    .ibox-title.locked {
        cursor: initial;
    }
}

[image="true"] > img {
    display: block;
    max-width: 100%;
    height: auto;
}

.sidebar-collapse { // I need to get rid of their style-attributes!
    //overflow-x: hidden !important;
    height: calc(~"100% - 20px");
    overflow-x: hidden;
    overflow-y: auto;
}

.chosen-results::-webkit-scrollbar,
.nav-second-level::-webkit-scrollbar,
.sidebar-collapse::-webkit-scrollbar {
    width: 4px;
}

.version-container {
    border-top: 1px solid #575756;
    cursor: pointer;
}

//smooth transition when expanding side-navigation
body:not(.mini-navbar) .nav-second-level.collapse,
body:not(.mini-navbar) .nav-second-level.collapsing { //the body-part is to prevent messing up the minified-navbar's behaviour
    opacity: 0;
}

.nav-second-level.collapse.in {
    opacity: 1 !important;
}

.nav-second-level {
    transition: opacity 0.25s;
}

.mini-navbar .nav-second-level {
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 150px;
    z-index: 1; //solves problem of next li rendered on top of nav-second-level
    left: 70px !important;
    .drop-shadow;
}

//toggle-switch
.toggle-switch label {
    background-color: @primary-hover-background;
    border: 2px solid @primary-hover-border;
    color: @primary-hover-color;
    user-select: none;
    width: calc(~"max-content + 30px");;
    overflow: hidden;
    height: 22px;
}

.toggle-switch label i { //toggler-pin
    background-color: @primary-hover-background;
    border: 1px solid @primary-hover-border;
    height: 100%;
    padding: 2px 7px;
    position: relative;
    left: -1px;
    transition: 0.5s;
    z-index: 200;
}

.toggle-switch input[type="checkbox"]:checked ~ label i {
    left: calc(~"100% - 15px"); //moving of toggler-pin
}

.toggle-switch label span { //translation-texts
    position: relative;
    padding: 0 5px;
    transition: 0.5s;
    display: block;
}

.toggle-switch input[type="checkbox"]:checked ~ label span:nth-child(2),
.toggle-switch input[type="checkbox"]:not(:checked) ~ label span:nth-child(3) {
    opacity: 0;
    transition: 0.2s;
}

.toggle-switch label span:nth-child(2) { //first translation
    top: -18px;
    padding-left: 20px;
}

.toggle-switch label span:nth-child(3) { //second translation
    top: -36px;
    padding-right: 20px;
}

.toggle-switch input[type="checkbox"] { display: none; }

//hidden checkbox

.animated {
    -webkit-animation-fill-mode: none; //fix for elements being rendered always on top of other elements, flashing through chosen-selects, etc.
    animation-fill-mode: none; //fix for elements being rendered always on top of other elements, flashing through chosen-selects, etc.
    z-index: auto !important; //fixes e.g. chosen being behind other panels (#578)
}

.date > h1.text-info:not(:hover) {
    color: @link-color !important; //overriding widget color (e.g. date/temp)
}

.date > h1.text-info:hover {
    color: @link-hover-color !important; //overriding widget color (e.g. date/temp)
}

.weatherForecast > tbody > tr > td {
    padding: 4px 8px;
    vertical-align: middle;
}

[ng-repeat="log in logs.logfiles | orderBy: 'date'"] {
    display: inline-block;
    margin: 2px;
}

#sidebar {
    height: 100%;
}

@media (max-width: 768px) {
    .ibox-tools { float: right !important; }

    //overriding inspinia.css
}

.ibox-title {
    white-space: nowrap;
}

.ibox-title h5 {
    max-width: calc(~"100% - 17px");
    padding-right: 5px;
    overflow: hidden;
}

.ibox-tools {
    max-width: 100%;
}

.ibox-tools-long { //stupid workaround/addition class for the constant misuse and constant over-stuffing of elements compared to their intended use. Why use a framework, if you break it anyway?
    overflow-x: auto;
    overflow-y: hidden;
    max-width: calc(~"100% - 150px") !important;
    //min-width: 50px !important;
    white-space: nowrap !important;
}

.ibox-tools-long * {
    white-space: nowrap !important;
    float: none !important; //preventing stupid linebreaks
}

.ibox-tools-long .btn-group .btn + .btn {
    margin-left: -5px; //compensating removed float
}

#breadcrumb-container {
    max-width: calc(~"100% - 230px");
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

.ibox-tools::-webkit-scrollbar,
#breadcrumb-container::-webkit-scrollbar { //chrome
    height: 3px;
}

#movBar {
    border: 1px solid @main-background;
    border-right: none;
    height: calc(~"100% - 50px");
}

#movBar > .scrollable-y {
    max-height: calc(~"100% - 50px"); //prevents uggly overflow of roomeditor-sidebar's content.
}

#movBar .nav-tabs > li > a { //fix for partlib-tabs only
    padding: 10px 10px 10px 10px;
}

.dropdown-menu { //also dropdowns in ibox-tools (e.g. "add widget" in reports)
    color: @main-foreground;
    background-color: @content-background;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    color: @main-foreground;
    background-color: @main-background;
}

.filelist {
    overflow: auto; // for responsive table
}

// Dropdown menu to load queries
.rsql-dropdown {
    //border-radius: 3px;
    color: inherit;
    line-height: 1; //25px;
    //margin: 4px;
    text-align: left;
    font-weight: normal;
}

.rsql-dropdown .animated {
    padding: 6px 22px; //3px 20px;
}

.rsql-dropdown > * > * > a {
    //border-radius: 3px;
    color: inherit;
    //line-height: 25px;
    margin: 4px;
    text-align: left;
    font-weight: normal;
}

.rsql-dropdown:hover {
    color: @table-hover-color; //#262626;
    text-decoration: none;
    background-color: @table-hover-background; //#f5f5f5;
}

.device-tiles .list-group {
    display: inline-block;
    float: none;
    padding-right: 0;
}

//@media (min-width: 768px) {//sm's min-width
@media (min-width: 992px) {
    .device-tiles .list-group.col-md-6 { //fix for different behaviour without float and with display: inline-block
        width: calc(~"50% - 3px");
    }
}

//@media (min-width: 992px) {//md's min-width
@media (min-width: 1200px) {
    .device-tiles .list-group.col-lg-4 { //fix for different behaviour without float and with display: inline-block
        width: calc(~"33.333% - 3px");
    }
}

.device-tiles .list-group:nth-child(4) {
    clear: left;
}

.hover-copy,
.hover-copy:hover {
    cursor: copy;
}

#detailPic { //image in info-panel (e.g. coolings) of roomeditor.
    width: auto;
    max-width: 100%;
    max-height: 100%;
    position: relative; //not 100% sure, if this is really needed
}

// Updateable field
.updateable { /* propably soon deprecated */
    background-color: transparent;
    transition: background-color .5s linear;
    -moz-transition: background-color .5s linear;
    -webkit-transition: background-color .5s linear;
    -ms-transition: background-color .5s linear;
}

.updateable-active { /* propably soon deprecated */
    background-color: @color-green;
}

@keyframes flashing { /* color-change for .flash-up class*/
    0% { background-color: transparent; }
    50% { background-color: @highlight-color; }
    100% { background-color: transparent }
}

.flash-up { /* meant to replace .updateable class with less js required for timeouts, etc.*/
    background-color: transparent;
    animation-name: flashing;
    animation-duration: 1.5s;
}

.multiSensorLabel,
.objectInfoLabel {
    position: absolute;
    padding: 1px 3px;
    background-color: @default-background;
    border-radius: 3px;
    pointer-events: none;
}

/* Cookie Bar */

#cookie-bar {
    background: @primary-background;
    height: auto;
    font-size: 12px;
    line-height: 14px;
    color: @primary-color;
    padding: 15px;
    margin-left: -15px;
    width: calc(~"100% + 30px");
    text-align: center;

    .cb-message {
        opacity: .85;
    }

    a {
        color: @primary-color;
        margin-left: 5px;
        text-decoration: underline;
    }

    a:hover {
        opacity: .85;
    }
}

#cookie-bar.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

#cookie-bar.fixed.bottom {
    bottom: 0;
    top: auto;
}

#cookie-bar p {
    margin: 0;
    padding: 0;
}

.cg-notify-close {
    color: @main-foreground !important;
    text-shadow: none;
}

.panel-primary {
    border-color: @primary-border;
}

.panel-primary > .panel-heading {
    background-color: @primary-background;
    border-color: @primary-border;
    color: @primary-color;
}

.td-button-grp {
    width: 1%;
    div {
        white-space: nowrap;
    }
    [href]:not(disabled="disabled") {
        cursor: pointer;
    }
}

.widgetDiv > div { //fix for sidescrolling widget-contents
    overflow-x: hidden;
}

//modals:
.modal-dialog {
    //max-height: calc(~"100% - 20px") !important;
    //overflow: auto;
}

//.panel-body .panel-body,
.ibox-content .tabs-container .panel-body { //saving some space on tabs in tabs
    padding: 5px;
}

.b-b-none {
    border-bottom: none !important;
}

.b-t-none {
    border-top: none !important;
}

.o-none:focus,
.o-none:hover,
.o-none:hover:focus {
    outline: none !important;
}

//experimental: overriding highcharts-vector-style
g.highcharts-button rect {
    fill: @btn-edit-background;
    stroke: @btn-edit-border;
}

g.highcharts-button:hover rect {
    fill: @btn-edit-hover-background;
    stroke: @btn-edit-hover-border;
}

g.highcharts-button > path {
    stroke: @btn-edit-color;
}

g.highcharts-button:hover > path {
    stroke: @btn-edit-hover-color;
}

.highcharts-contextmenu div div {
    font-size: 13px !important;
    color: @main-foreground;
    padding: 3px 5px !important;
}

.highcharts-contextmenu div { //todo
    background: @content-background !important;
    color: @main-foreground !important;
}

.highcharts-contextmenu div div:hover {
    color: @primary-color !important;
    background-color: @primary-background !important;
}

.highcharts-contextmenu hr {
    margin: 5px 0;
}

.energy-cockpit {
    .list-group-item {
        background-color: RGBA(255, 255, 255, .8);
        border-left: none;
        border-right: none;
    }
    .list-group-item:first-child {
        border-top: none;
        margin-top: -15px;
    }
    .chart-container {
        svg {
            padding-top: 5px;
        }
        text[x="0"] {
            display: none !important;
        }
    }
}

td > heatmap-gradient > canvas { /* expands administration-heatmap-table's gradients to full td's width. */
    height: 10px;
}

heatmap-gradient > canvas {
    width: 100%;
}

// highcharts-wahnsinn:
widgetanalysis .highcharts-title > tspan,
widgetanalysis .highcharts-subtitle > tspan,
widgetanalysis .highcharts-legend-item > text > tspan,
widgetanalysis .highcharts-axis-labels > text > tspan,
widgetenergycockpit .highcharts-axis-labels > text > tspan,
widgetenergymeter .highcharts-title > tspan,
widgetenergymeter .highcharts-subtitle > tspan,
widgetenergymeter .highcharts-legend-item > text > tspan,
widgetenergymeter .highcharts-axis-labels > text > tspan,
widgetspeedometer .highcharts-title > tspan,
widgetspeedometer .highcharts-subtitle > tspan,
widgetweatherforecast .highcharts-title > tspan,
widgetweatherforecast .highcharts-subtitle > tspan,
widgetweatherforecast .highcharts-legend-item > text > tspan,
widgetweatherforecast .highcharts-axis-labels > text > tspan,
.analysis-container .highcharts-title > tspan,
.analysis-container .highcharts-subtitle > tspan,
.analysis-container .highcharts-legend-item > text > tspan,
.analysis-container .highcharts-axis-labels > text > tspan {
    color: @main-foreground;
    fill: @main-foreground;
}

widgetspeedometer .highcharts-title,
widgetspeedometer .highcharts-subtitle {
    color: @main-foreground !important;
    fill: @main-foreground !important;
}

widgetspeedometer .highcharts-center-value, .highcharts-center-unit {
    color: @main-foreground;
    fill: @main-foreground;
}

widgetspeedometer .highcharts-axis-labels > text > tspan {
    color: @main-foreground;
    fill: @main-foreground;
}

.widgetspeedometer-background {
    background-color: @main-background;
    fill: @main-background;
}

widgetspeedometer .ibox-content {
    padding: 0px;
}

.highcharts-background {
    fill: @content-background;
}

.nav.nav-tabs li:not(.active):not(:hover) a {
    background-color: @form-control-disabled-background;
    opacity: 0.5;
}

.mirror {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

widgetsysteminfo .popover-content {
    color: black !important;
}

div.popover-content {
    color: black !important;
}

.w-100-px {
    width: 100px !important;
}

.custom-field-row:first-child .bring-up {
    pointer-events: none;
    display: none;
}

.custom-field-row:last-child .bring-down {
    pointer-events: none;
    display: none;
}

.border-radius-label-warning {
    border-radius: 3px;
    padding-left: 3px;
    padding-right: 3px;
}

.info-icon-background {
    pointer-events: auto;
    background: radial-gradient(#ffffff 5.5px, transparent 5px);
}

.text-widget {
    width:100%;
    max-width: 100%;
    box-sizing: border-box;
}

.insufficientSpace {
  background-color: @color-red;
}

.dbSizeContainer {
  color: #fff;
  font-style: bold;
  line-height: 1,33333;
  background-color: @primary-background;
  border: 1px solid @primary-border;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 18px;
}

//mwl-calendar section --start--

mwl-calendar .cal-slide-content {
    background-color: @modal-background !important;
    box-shadow: none !important;
}

mwl-calendar .cal-day-today {
    background-color: #51bec8;
}

mwl-calendar .cal-day-open {
    background-color: #00adba;
}

mwl-calendar .badge-important {
    background-color: @tooltip-background;
}
mwl-calendar .badge {
    color: @primary-color;
}

mwl-calendar .cal-day-today span[data-cal-date] {
    color: @primary-color;
}

.cal-week-box-cell {
    display: none !important;
}


mwl-calendar .cal-row-fluid:hover, mwl-calendar .cal-year-box .row:hover {
    background-color: @table-hover-background;
}

mwl-calendar .cal-cell:hover {
    background-color: @table-striped-background;
}

mwl-calendar .cal-slide-content {
    background-color: #00adba !important;
    border-right: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
}

mwl-calendar .cal-day-tick {
    border: 1px solid #e1e1e1;
    border-top: 0 solid;
    border-radius: 0 0 5px 5px;
    background-color: @modal-background;
}

mwl-calendar .cal-day-weekend span[data-cal-date] {
    color: @badge-danger-background;
}

mwl-calendar .cal-month-box .cal-row-head [class*=cal-cell]:hover {
    background-color: @table-striped-background;
}
mwl-calendar .events-list {
    max-height: 47px;
    padding-left: 5px;
    overflow: hidden !important;
}

mw.clendar event-item #text {
    display: none !important;
}
//mwl-calendar section --end--


.box-group {
     text-align: center;
     background-color: @form-control-background;
     border: @form-control-border;
     padding: 5px;
     margin: 2px;
 }

.slidecontainer {
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    background: @content-background;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: @default-color;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: @default-color;
    cursor: pointer;
}

#mailsettings-bar {
    background: #ffd500 !important;
    height: auto;
    font-size: 12px;
    line-height: 14px;
    color: @primary-color;
    padding: 15px;
    margin-left: -15px;
    width: calc(~"100% + 30px");
    text-align: center;

    .mb-message {
        opacity: .85;
    }

    a {
        color: @primary-color;
        margin-left: 5px;
        text-decoration: underline;
    }

    a:hover {
        opacity: .85;
    }
}

#mailsettings-bar.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

#mailsettings-bar.fixed.bottom {
    bottom: 0;
    top: auto;
}

#mailsettings-bar p {
    margin: 0;
    padding: 0;
}

#freeVersionBadge {
    position: absolute;
    z-index: 999999;
    bottom: 20px;
    opacity: 75%;
    width: 100%;
    height: 100px;
    background: #337ab7;
    overflow: hidden;
    color: white;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
}

#freeVersionBadge p {
    -ms-transform: rotate(25deg); /* IE 9 */
    transform: rotate(25deg);
    margin: 60px 40px;
}

body.mini-navbar #freeVersionBadge {
    display: none;
}

#watermark {
    display: flex;
    position: absolute;
    width : 100%;
    flex-direction: column;
    z-index: 999999;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 100px;
    opacity: 10%;
}

//Spinner for Datapoints
.spinner-row {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.spinner-align-center-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.spinneroverlay {
    position: absolute;
    z-index: 1000;
    background-color: @main-background;
    opacity: 0.7;
}

.spinnerRoomOverview {
    height: calc(~"100% + 20px");
    width: calc(~"100% + 20px");
    top: -5px;
    left: -20px;
}

.spinnerRoomEdit {
    height: calc(~"100% - 95px");
    width: calc(~"100% - 30px");
}

.pointer {
    cursor:pointer;
}

//slider

input:checked + .slider {
    background-color: @primary-background !important;
}

.primaryBGContainer {
    color: #fff;
    font-style: bold;
    line-height: 1,33333;
    text-align: center;
    background-color: @primary-background;
    border: 1px solid @primary-border;
    border-radius: 6px;
    padding: 10px 16px;
    font-size: 18px;
    margin-right: 15px;
}

.primaryBGContainerLarge {
    color: #fff;
    font-style: bold;
    line-height: 1,33333;
    text-align: center;
    background-color: @primary-background;
    border: 1px solid @primary-border;
    border-radius: 6px;
    padding: 10px 16px;
    font-size: 24px;
    margin-right: 15px;
}

.primaryBGInfoLabel {
    color: @primary-background;
    font-style: bold;
    line-height: 1,33333;
    text-align: center;
    background-color: #fff;
    border-radius: 6px;
    padding: 10px 16px;
    font-size: 21px;
    margin-right: 15px;
}

.required:after {
        content: " *";
}

.form-group-error{
    position: relative;
    margin-top: -12px;
    font-size: 12px;
    color: red;
    padding-left: 3px;
}

.event-item-action {
    color: #fff !important;
}

.daterangepicker select.minuteselect {
    background: none !important;
}
.daterangepicker select.hourselect {
    background: none !important;
}

h6 {
    font-size: 14px;
    font-weight: bold;
}


flex-row-content {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

flex-start {
    align-items: flex-start;
}