/* variables (placeholder to let gulp run smoothly, get overridden anyway) */
@import "fontello/fontello.css";
.mini-navbar .nav-header {
  background-image: url("/assets/ci/cyberhub-light/images/cyberHub_logo_mini.svg") !important;
  /*important because inspinia overrides*/
}
body {
  background: #e1e1e1;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #575756;
}
a {
  color: #00adba;
  -webkit-user-drag: none;
}
a:hover {
  color: #51bec8;
}
hr {
  border-top: 1px solid #eee !important;
  width: 100%;
}
input {
  max-width: 100%;
}
input[type="checkbox"] {
  cursor: pointer;
}
input[type="number"],
input[type="text"] {
  background-color: #FFFFFF;
  border: 1px solid #e1e1e1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type="number"]:focus,
input[type="text"]:focus {
  border: 1px solid #00adba !important;
  outline: none;
}
input[type="range"] {
  display: inline-block;
  -webkit-appearance: none;
  background-color: transparent;
  min-width: 50px;
  cursor: w-resize;
}
input[type="range"][orient="vertical"] {
  cursor: n-resize;
  writing-mode: bt-lr;
  height: 50px;
}
/*Chrome*/
input[type="range"][orient="vertical"] {
  -webkit-appearance: slider-vertical;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #a1a1a1;
  height: 16px;
  width: 8px;
  margin-top: -7px;
}
input[type="range"][orient="vertical"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #a1a1a1;
  height: 8px;
  width: 16px;
  margin-top: 0px;
  margin-left: -10px;
}
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #e1e1e1;
  height: 2px;
  border-left: 2px solid #a1a1a1;
  border-right: 2px solid #a1a1a1;
}
input[type="range"][orient="vertical"]::-webkit-slider-runnable-track {
  width: 1px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}
/*FF*/
input[type="range"]::-moz-range-thumb {
  background-color: #a1a1a1;
  height: 16px;
  width: 8px;
}
input[type="range"][orient="vertical"]::-moz-range-thumb {
  height: 8px;
  width: 16px;
}
input[type="range"]::-moz-range-track {
  height: 2px;
  background-color: #f5f5f5;
}
input[type="range"][orient="vertical"]::-moz-range-track {
  width: 2px;
  height: 100%;
}
/*IE*/
input[type="range"]::-ms-tooltip {
  display: none;
}
input[type="range"]::-ms-track {
  height: 2px;
  background: transparent;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
input[type="range"][orient="vertical"]::-ms-track {
  width: 2px;
  height: 50px;
  background: transparent;
  border-top: 0;
  border-bottom: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  background: #e1e1e1;
}
input[type="range"]::-ms-thumb {
  background: #a1a1a1;
  height: 16px;
  width: 8px;
}
input[type="range"][orient="vertical"]::-ms-thumb {
  height: 8px;
  width: 16px;
}
/*label[for]{ //removed because of feedback from MA
    cursor: pointer;
}*/
select {
  cursor: pointer;
}
select::-ms-expand {
  /* IE-caret fix */
  display: none;
}
select:not(:root:root) {
  /* safari */
  padding-left: 5px;
}
select:not([multiple]) {
  background-color: #FFFFFF;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 2px 18px 2px 5px;
  /*needed to prevent overlapping*/
  background-image: url("/assets/ci/cyberhub-light/images/arrow-down.png") !important;
  /*important because inspinia overrides*/
  background-repeat: no-repeat;
  background-position: calc(100% - 4px) 50%;
  max-width: 100%;
}
::selection {
  background-color: #00adba;
  color: #FFFFFF;
}
/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}
/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc;
}
/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0;
  background: #fff;
  width: 100%;
  padding: 15px;
  height: 100%;
}
.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px;
}
.hero-unit h1 {
  font-size: 60px;
  line-height: 1;
  letter-spacing: -1px;
}
.error {
  color: white;
  background-color: red;
}
.hipster {
  display: inline-block;
  width: 347px;
  height: 497px;
  background-size: contain;
}
/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .hipster {
    background-size: contain;
  }
}
.hand {
  cursor: pointer;
}
#threadDump .popover,
#healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px;
}
#healthCheck .popover {
  margin-left: -50px;
}
.health-details {
  min-width: 400px;
}
.alert .popover pre {
  font-size: 10px;
}
.voffset {
  margin-top: 2px;
}
.voffset1 {
  margin-top: 5px;
}
.voffset2 {
  margin-top: 10px;
}
.voffset3 {
  margin-top: 15px;
}
.voffset4 {
  margin-top: 30px;
}
.voffset5 {
  margin-top: 40px;
}
.voffset6 {
  margin-top: 60px;
}
.voffset7 {
  margin-top: 80px;
}
.voffset8 {
  margin-top: 100px;
}
.voffset9 {
  margin-top: 150px;
}
/* start Password strength bar style */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0 0 0 15px;
  padding: 0;
  vertical-align: 2px;
}
.point:last {
  margin: 0 !important;
}
.point {
  background: #DDD;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px;
}
/* end Password strength bar style */
.top-0 {
  margin-top: 0;
}
.top-5 {
  margin-top: 5px;
}
.top-10 {
  margin-top: 10px;
}
.top-20 {
  margin-top: 20px;
}
.bottom-0 {
  margin-bottom: 0;
}
.bottom-5 {
  margin-bottom: 5px;
}
.bottom-10 {
  margin-bottom: 10px;
}
.bottom-20 {
  margin-bottom: 20px;
}
.p-r-0 {
  padding-right: 0 !important;
}
.full-width {
  width: 100%;
}
.mini-width {
  width: 1%;
}
.text-green {
  color: #00E466;
}
.text-big {
  font-size: 24px;
}
.text-huge {
  font-size: 36px;
}
.text-titanic {
  font-size: 64px;
}
.text-orange {
  color: #ff5a00;
}
.text-red {
  color: #e30613;
}
.text-vertical {
  writing-mode: vertical-lr;
  margin-left: calc(50% - 8px);
}
label.disabled {
  color: darkgrey;
}
.uppercase {
  text-transform: uppercase;
}
.tree-selected {
  color: #00adba;
  font-weight: bold;
}
#content {
  padding-top: 14px;
}
h2 {
  margin-bottom: 20px;
}
.form-image {
  border: 1px solid #ddd;
}
/* Fix chosen bug with width: 0px (https://github.com/harvesthq/chosen/issues/1548) */
.chosen-container {
  min-width: 100%;
  max-width: 100%;
}
/* Animate glyphicon loading */
.glyphicon-refresh-animate {
  animation: spin 0.7s infinite linear;
  -ms-animation: spin 0.7s infinite linear;
  -webkit-animation: spin 0.7s infinite linear;
  -moz-animation: spin 0.7s infinite linear;
}
@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
@-webkit-keyframes spinw {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spinm {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
/* A scrollable box */
.scroll-box {
  height: 300px;
}
/* White modal dialogs */
.app-modal-window .modal-dialog {
  float: right;
  min-width: 600px;
  min-height: 300px;
  background-color: #ffffff;
  border-top: 4px #e7eaec solid;
  /*-webkit-box-shadow: 0px 0px 5px 5px rgba(255,255,255,0.1);*/
  /*-moz-box-shadow: 0px 0px 5px 5px rgba(255,255,255,0.1);*/
  /*box-shadow: 0px 0px 5px 5px rgba(255,255,255,0.1);*/
}
.modal-content {
  background-color: #ffffff !important;
}
.modal-dialog .modal-content {
  box-shadow: none;
}
.modal.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  z-index: 2041 !important;
}
.green {
  color: #00E466;
}
.red {
  color: #e30613;
}
.yellow {
  color: #fff8ad;
}
.blue {
  color: #0000cc;
}
.row-error {
  background-color: #fff;
}
.row-error:hover {
  background-color: #cd9b9e;
}
.row-warning {
  background-color: #fff;
}
.row-warning:hover {
  background-color: #cdc798;
}
.row-informal {
  background-color: #fff;
}
.row-informal:hover {
  background-color: #93b0cd;
}
.progress {
  background-color: #ccc;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}
.progress-bar {
  background-color: #00adba;
}
.progress-bar-text {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 1px;
  text-align: center;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0px 0px 10px black;
}
/** Background if trial has ended. */
.trial-ended {
  background-color: #4D0300 !important;
  /* #FF621A */
}
.trial-ended-footer {
  position: absolute;
  z-index: 99999;
  bottom: 10px;
  color: #000951;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
/** Normal text in sidebar. */
.nav-text {
  color: #000000;
}
.table > thead > th,
.table > thead > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle;
}
#roomOverviewTable > tbody > tr > td {
  vertical-align: top !important;
}
.table-striped > tbody > tr:nth-of-type(odd):not(.bg-info) {
  background-color: #f9f9f9;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #e1e1e1;
  color: #a1a1a1;
  opacity: 1;
}
.form-group-sm .form-control,
.form-group-sm .form-control-static {
  font-size: 13px;
}
.form-control-static {
  overflow-wrap: break-word;
}
.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
  color: #000000;
}
.table tbody td:first-child {
  min-width: 55px;
}
.table td:last-child.text-right {
  white-space: nowrap;
}
.table-responsive .chosen-container {
  position: relative;
}
.table-responsive .chosen-container.chosen-container-active {
  position: relative;
}
.chosen-container-single,
.chosen-container,
.chosen-single,
.chosen-multi {
  background-color: #FFFFFF !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0;
}
.chosen-container-multi {
  min-height: 34px;
}
.chosen-container-multi .chosen-choices {
  padding-top: 2px;
}
.chosen-container > a,
.chosen-single > a,
.chosen-multi > a {
  border: none;
}
.chosen-container-single .chosen-single {
  border: none !important;
}
.chosen-container-active {
  border: 1px solid #00adba !important;
}
.chosen-container-active.chosen-with-drop .chosen-single {
  border: none !important;
  background: inherit !important;
  box-shadow: none !important;
}
.chosen-single span {
  color: #575756;
}
.chosen-container .chosen-drop {
  border: 1px solid #e1e1e1 !important;
  background: #FFFFFF !important;
}
.chosen-container-multi .chosen-choices {
  background: #FFFFFF !important;
  border: none;
}
.chosen-container-multi .chosen-choices li.search-choice {
  color: #FFFFFF;
  background: #c2c2c2;
  border-color: #c2c2c2;
}
.chosen-container-multi .chosen-drop .result-selected {
  color: #a1a1a1;
}
.chosen-container .chosen-results li.highlighted {
  background-color: #FFFFFF !important;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, #00adba), color-stop(90%, #00adba)) !important;
  background-image: -webkit-linear-gradient(#00adba 20%, #00adba 90%) !important;
  background-image: -moz-linear-gradient(#00adba 20%, #00adba 90%) !important;
  background-image: -o-linear-gradient(#00adba 20%, #00adba 90%) !important;
  background-image: linear-gradient(#00adba 20%, #00adba 90%) !important;
  color: #575756 !important;
}
.fa-border {
  border-color: #e1e1e1 !important;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5 !important;
  color: #000000;
}
.logo-insert {
  width: 180px;
  height: 38px;
  pointer-events: none;
}
.ibox-tools a.btn-create {
  color: #FFFFFF;
}
.ibox-tools a.btn-primary {
  color: #FFFFFF;
}
.ibox-tools a.btn-primary:hover {
  color: #00adba !important;
}
.radio label::before {
  border: 1px solid #e1e1e1 !important;
}
.radio-primary input[type="radio"]:checked + label::before {
  background-color: #ffffff !important;
}
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #00adba !important;
}
.login-background {
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../images/bg_stulz.jpg") no-repeat;
  background-color: #ffffff;
  background-size: cover !important;
  height: 100% !important;
  width: 100% !important;
  position: fixed;
  overflow: auto;
}
.logo-login {
  width: 100%;
}
.blankPages {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.license-warning {
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #00adba !important;
  border-color: #00adba !important;
}
.logo-headline::before {
  content: "";
}
.copy-color {
  color: inherit;
  font-weight: bold;
}
.breadcrumb {
  border-radius: 0 !important;
}
.breadcrumb {
  border-bottom: none !important;
  padding-top: 20px;
  padding-left: 20px;
}
.breadcrumb li.active span {
  font-weight: bold;
}
.navbar-fixed-top,
.navbar-static-top {
  background-color: #ffffff !important;
}
.nav-headline::after {
  content: "";
}
.borderless {
  border: none !important;
}
.mark-personal-template:before {
  content: '(Personal) ';
}
.mark-group-template:before {
  content: '(Group) ';
}
.mark-system-template:before {
  content: '(System) ';
}
.v-middle {
  vertical-align: middle !important;
}
.cell-highlight > * {
  box-shadow: 0 0 5px 1px #e30613;
}
/* Scrollbar-fixes */
/* Chrome */
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  background: #a1a1a1;
}
/* IE */
* {
  scrollbar-face-color: #a1a1a1;
  scrollbar-track-color: #f5f5f5;
}
/* Skin for the color picker */
.sp-replacer,
.sp-replacer:hover {
  background: #FFFFFF;
  border: solid 1px #e1e1e1;
  color: inherit;
}
.sp-active {
  border-color: #00adba !important;
}
.sp-active .sp-dd {
  color: #575756;
}
.sp-container {
  background-color: #ffffff;
  border: none !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.sp-input {
  color: #575756;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0 !important;
}
.sp-input :focus {
  border-color: #00adba !important;
}
.sp-picker-container {
  border: none;
}
.sp-container button {
  background: #ffffff;
  border: 1px solid #e7eaec;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
}
.sp-preview {
  max-width: 50%;
}
.fullscreen-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #212121;
  display: table;
}
.widget-border {
  border: #e7eaec 1px solid;
}
.page-portrait {
  height: 1370px;
  min-height: 1370px;
  max-height: 1370px;
  display: block;
  overflow: hidden;
}
.page-landscape {
  height: 910px;
  min-height: 910px;
  max-height: 910px;
  display: block;
  overflow: hidden;
}
.report-preview .ibox-content {
  border: none;
}
.report-preview .leaflet-control-container {
  visibility: hidden;
  display: none;
}
.leaflet-touch .leaflet-bar {
  border: none;
}
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font-size: 18px !important;
  text-align: center;
  width: 34px !important;
  color: #a1a1a1 !important;
  border: 1px solid #a1a1a1 !important;
  background-color: #ffffff !important;
}
.leaflet-control-zoom-out {
  border-top: none !important;
}
.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
  color: #FFFFFF !important;
  background-color: #a1a1a1 !important;
  border-color: #a1a1a1 !important;
}
.btn-download,
.btn-download:focus:not([disabled]) {
  color: #a1a1a1;
  background-color: #ffffff;
  border: 1px solid #a1a1a1;
}
.btn-download:hover:not([disabled]) {
  color: #FFFFFF;
  background-color: #a1a1a1;
  border-color: #a1a1a1;
}
.btn-back,
.btn-back:focus:not([disabled]) {
  color: #a1a1a1;
  background-color: #ffffff;
  border: 1px solid #a1a1a1;
}
.btn-back:hover:not([disabled]) {
  color: #FFFFFF;
  background-color: #a1a1a1;
  border-color: #a1a1a1;
}
.btn-cancel,
.btn-cancel:focus:not([disabled]) {
  color: #FFFFFF;
  background-color: #e30613;
  border: 1px solid #e30613;
}
.btn-cancel:hover:not([disabled]) {
  color: #e30613;
  background-color: #FFFFFF;
  border-color: #e30613;
}
.btn-edit,
.btn-edit:focus:not([disabled]) {
  color: #a1a1a1;
  background-color: #ffffff;
  border: 1px solid #a1a1a1;
}
.btn-edit:hover:not([disabled]) {
  color: #FFFFFF;
  background-color: #a1a1a1;
  border-color: #a1a1a1;
}
.btn-default[disabled]:hover {
  color: #FFFFFF;
}
.btn-delete,
.btn-delete:focus:not([disabled]) {
  color: #FFFFFF;
  background-color: #e30613;
  border: 1px solid #e30613;
}
.btn-delete:hover:not([disabled]) {
  color: #e30613 !important;
  background-color: #FFFFFF;
  border-color: #e30613;
}
.btn-delete[disabled],
.btn-delete[disabled]:hover {
  color: #FFFFFF !important;
}
.btn-move,
.btn-move:focus:not([disabled]) {
  color: #a1a1a1;
  background-color: #ffffff;
  border: 1px solid #a1a1a1;
}
.btn-move:hover:not([disabled]) {
  color: #FFFFFF;
  background-color: #a1a1a1;
  border-color: #a1a1a1;
}
.btn-create,
.btn-create:focus:not([disabled]) {
  color: #FFFFFF;
  background-color: #00adba;
  border: 1px solid #00adba;
}
.btn-create:hover:not([disabled]) {
  color: #00adba;
  background-color: #ffffff;
  border-color: #00adba;
}
.btn-create[disabled]:hover {
  color: #FFFFFF !important;
}
.btn-details,
.btn-details:focus:not([disabled]) {
  color: #a1a1a1;
  background-color: #ffffff;
  border: 1px solid #a1a1a1;
}
.btn-details:hover:not([disabled]) {
  color: #FFFFFF;
  background-color: #a1a1a1;
  border-color: #a1a1a1;
}
.btn-details[disabled]:hover {
  color: #a1a1a1;
}
.btn-view,
.btn-view:focus:not([disabled]) {
  color: #a1a1a1;
  background-color: #ffffff;
  border: 1px solid #a1a1a1;
}
.btn-view:hover:not([disabled]) {
  color: #FFFFFF;
  background-color: #a1a1a1;
  border-color: #a1a1a1;
}
.btn-remove,
.btn-remove:focus:not([disabled]) {
  color: #FFFFFF;
  background-color: #e30613;
  border: 1px solid #e30613;
}
.btn-remove:hover:not([disabled]) {
  color: #e30613;
  background-color: #FFFFFF;
  border-color: #e30613;
}
.btn-apply,
.btn-apply:focus:not([disabled]) {
  color: #FFFFFF;
  background-color: #00adba;
  border: 1px solid #00adba;
}
.btn-apply:hover:not([disabled]) {
  color: #00adba;
  background-color: #ffffff;
  border-color: #00adba;
}
.btn-apply[disabled]:hover {
  color: #FFFFFF !important;
}
.btn-add {
  color: #FFFFFF;
  background-color: #00adba;
  border: 1px solid #00adba;
}
.btn-add:focus:not([disabled]) {
  color: #FFFFFF;
  background-color: #00adba;
  border-color: #00adba;
}
.btn-add:hover:not([disabled]) {
  color: #00adba;
  background-color: #ffffff;
  border-color: #00adba;
}
.btn-primary:focus:not([disabled]) {
  color: #FFFFFF;
  background-color: #00adba;
  border-color: #00adba;
}
.btn-primary:hover:not([disabled]) {
  color: #00adba;
  background-color: #ffffff;
  border-color: #00adba;
}
.btn-save {
  color: #FFFFFF;
  background-color: #00adba;
  border: 1px solid #00adba;
}
.btn-save:focus:not([disabled]) {
  color: #FFFFFF;
  background-color: #00adba;
  border-color: #00adba;
}
.btn-save:hover:not([disabled]) {
  color: #00adba;
  background-color: #ffffff;
  border-color: #00adba;
}
.btn-save[disabled]:hover,
.btn-add[disabled]:hover {
  color: #FFFFFF;
}
.btn-success,
.btn-success[disabled],
.btn-success[disabled]:hover {
  color: #fff;
  background-color: #1e8961;
  border-color: #1e8961;
}
.btn-success:focus:not([disabled]) {
  color: #fff;
  background-color: #1e8961;
  border-color: #1e8961;
}
.btn-success:hover:not([disabled]) {
  color: #1e8961;
  background-color: #fff;
  border-color: #1e8961;
}
.btn-close,
.btn-close:focus:not([disabled]) {
  color: #a1a1a1;
  background-color: #ffffff;
  border: 1px solid #a1a1a1;
}
.btn-close:hover:not([disabled]) {
  color: #FFFFFF;
  background-color: #a1a1a1;
  border-color: #a1a1a1;
}
.btn-white,
.btn-white:focus:not([disabled]) {
  border: 1px solid #a1a1a1 !important;
}
.btn-white:hover:not([disabled]) {
  border-color: #a1a1a1 !important;
}
.btn-controlround {
  text-align: center;
  min-width: 75px;
  max-width: 170px;
  min-height: 75px;
  max-height: 170px;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-controlround span {
  display: inline-block;
  vertical-align: middle;
  max-width: 90%;
}
select.sel-btn-sm {
  display: inline-block;
  width: calc(100% - 40px);
}
.close {
  color: #575756;
  opacity: 0.5;
}
.close:hover {
  color: #575756;
  opacity: 1;
}
.ibox-tools .checkbox {
  margin-top: 0;
}
.nav > li > a i {
  font-size: 20px;
}
.ibox-tools > .checkbox {
  margin-top: 0;
}
.nav > li > a i {
  font-size: 20px;
}
.tooltip-inner {
  color: #ffffff;
  background-color: #a1a1a1 !important;
  border: 1px solid #a1a1a1;
  max-width: 250px;
}
.tooltip-inner,
.tooltip.in {
  opacity: 0.95;
  filter: alpha(opacity=95);
}
.tooltip.top .tooltip-arrow {
  border-top-color: #a1a1a1 !important;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #a1a1a1 !important;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #a1a1a1 !important;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #a1a1a1 !important;
}
.drop-shadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.badge-danger,
.label-danger {
  background-color: #e30613 !important;
}
.badge-warning {
  background-color: #ffd500 !important;
  color: black !important;
}
.badge-info {
  background-color: #006598 !important;
}
.badge-historical {
  background-color: #a9a9a9 !important;
}
.badge-custom {
  color: #5e5e5e !important;
  border-color: #5e5e5e !important;
  background-color: #e5e5e5 !important;
}
.badge-pulse {
  animation-name: badge-pulse;
  animation-duration: 0.3s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}
@keyframes badge-pulse {
  from {
    transform: scale(1);
    background: #e30613;
  }
  to {
    transform: scale(1.3);
    background: #ff0000;
  }
}
.navbar-default .nav > li > a:hover {
  background: #51bec8 !important;
  background-color: #51bec8 !important;
}
.navbar-default .nav .arrow {
  padding-top: 7px;
}
.webglContainer {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.roomEditorSideBar {
  width: calc(30%);
  height: calc(88% - 130px);
  background-color: 'rgba(0,0,0,0)';
  pointer-events: none;
  display: inline-block;
  position: absolute;
  top: 130px;
  left: 69%;
}
/* MK SDS Animation etc */
.transitionSidebar {
  width: 50px;
  height: 100%;
  background-color: #ffffff;
  float: right;
  display: inline-block;
  transition-property: width;
  -webkit-transform: width 1s;
  transition: width 1s;
  pointer-events: auto;
}
.editorFullScreen {
  position: fixed;
  top: 5px;
  left: 5px;
  bottom: 5px;
  right: 5px;
  border: 1px solid #222;
  z-index: 2001;
}
.editorFullScreen > .ibox-content {
  height: calc(100% - 32px);
}
.editorFullScreen .glOverlayContainer {
  top: 1%;
}
/*overriding style:*/
.onoffswitch-label {
  border-color: #00adba;
}
.onoffswitch-switch {
  border-color: #00adba;
  background: #FFFFFF;
}
.onoffswitch-inner:before {
  background-color: #00adba;
  color: #FFFFFF;
}
.onoffswitch-inner:after {
  background-color: #ffffff;
  color: #00adba;
}
.onoffswitch.roomdevicesswitch {
  width: 74px !important;
}
.roomdevicesswitch .onoffswitch-switch {
  right: 56px !important;
}
.roomdevicesswitch .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px !important;
}
#toolButtonsMin button,
#toolButtonsMax button {
  font-size: 16px;
}
#toolButtonsMin button,
#partlibButtonsMin button {
  margin: 1px auto 1px calc(50% - 22px);
}
#toolButtonsMin button[disabled],
#toolButtonsMax button[disabled] {
  background-color: #e1e1e1;
  color: #a1a1a1;
}
#toolButtonsMax button,
#toolSettings button {
  margin-bottom: 4px;
}
.btn-browse {
  margin: 3px;
  margin-left: calc(50% - 12px);
}
.separator {
  height: 1px;
  width: 100%;
  background-color: #c2c2c2;
  margin: 5px 0px;
}
#scrollWrapper {
  overflow: hidden;
}
.scrollable {
  overflow: auto;
}
.scrollable-y {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  height: 100%;
}
.ibox-content .nav-tabs > li.active > a,
.ibox-content .nav-tabs > li.active > a:focus,
.ibox-content .nav-tabs > li.active > a:hover,
.tabs-container .nav-tabs > li.active > a,
.tabs-container .nav-tabs > li.active > a:focus,
.tabs-container .nav-tabs > li.active > a:hover {
  background-color: #fff !important;
  color: #000;
  border-color: #e7eaec #e7eaec rgba(0, 0, 0, 0) #e7eaec !important;
}
[ng-show="ctrl.detail.selectedUsedParam !== null"] .panel-body,
.ibox-content .panel-body,
.tabs-container .panel-body {
  background: #fff !important;
  border-color: #e7eaec !important;
}
[ng-show="ctrl.detail.selectedUsedParam !== null"] .nav-tabs,
.ibox-content .nav-tabs,
.tabs-container .nav-tabs {
  border-color: #e7eaec !important;
}
[ng-show="ctrl.detail.selectedUsedParam !== null"] .nav-tabs > li > a:hover,
.ibox-content .nav-tabs > li > a:hover,
.tabs-container .nav-tabs > li > a:hover {
  color: #000;
  background: #fff;
  border-color: #e7eaec #e7eaec rgba(0, 0, 0, 0) #e7eaec !important;
}
.list-group-item {
  border-color: #e7eaec !important;
}
a.list-group-item {
  color: #575756;
}
a.list-group-item:hover {
  color: #575756;
  background: #f5f5f5;
}
a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  background-color: #00adba;
  color: #FFFFFF;
}
.panel-default {
  border-color: #e7eaec;
}
.panel-default > .panel-heading {
  background: #e1e1e1;
  color: #575756;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: transparent;
}
.daterangepicker {
  background-color: #ffffff;
}
.daterangepicker:before {
  border-bottom: 7px solid rgba(86, 96, 117, 0.7);
}
.daterangepicker:after {
  border-bottom: 6px solid #ffffff !important;
}
.daterangepicker td.off {
  background-color: transparent;
  color: #a1a1a1;
}
.calendar .calendar-table {
  background-color: transparent;
}
.calendar-table > .table-condensed {
  border-collapse: separate;
}
.calendar-table td.active,
.calendar-table td.active:hover {
  background-color: #00adba;
  color: #FFFFFF;
}
.calendar-table td.available:hover:not(.active) {
  background-color: #ffffff;
  color: #00adba;
  border-color: #00adba;
}
.calendar-table th.next:hover,
.calendar-table th.prev:hover {
  background-color: #bababa !important;
  color: #FFFFFF !important;
}
input.overlayInput {
  color: #575756;
  background-color: #FFFFFF;
  border: 1px solid #e1e1e1;
  width: 100%;
  text-align: center;
}
/*----------------------------------*/
/*-------------- dPad --------------*/
/*----------------------------------*/
div[class^="dPad"] {
  transition: color 0.4s, background-color 0.4s, background 0.4s;
  color: #FFFFFF;
}
div[class^="dPad"]:not(.dPad):not(.dPadSubL):not(.dPadSubR),
.alignContainer,
.dPadCamRaise,
.dPadCamSink {
  position: absolute;
  pointer-events: auto;
}
div[class^="dPad"]:not(.dPad):not(.dPadSubL):not(.dPadSubR) > span,
.alignContainer > span,
.dPadCamRaise > span,
.dPadCamSink > span {
  cursor: pointer;
}
.dPad {
  opacity: 0.95;
  height: 100px;
  width: 100px;
  pointer-events: auto;
  background-image: radial-gradient(circle 30px at 0 0, rgba(0, 0, 0, 0) 29px, #c2c2c2 30px, #c2c2c2 70px, rgba(0, 0, 0, 0) 71px), radial-gradient(circle 30px at 100px 0, rgba(0, 0, 0, 0) 29px, #c2c2c2 30px, #c2c2c2 70px, rgba(0, 0, 0, 0) 71px), radial-gradient(circle 30px at 0 100px, rgba(0, 0, 0, 0) 29px, #c2c2c2 30px, #c2c2c2 70px, rgba(0, 0, 0, 0) 71px), radial-gradient(circle 30px at 100px 100px, rgba(0, 0, 0, 0) 29px, #c2c2c2 30px, #c2c2c2 70px, rgba(0, 0, 0, 0) 71px);
  border-radius: 50%;
  font-size: 20px;
  margin: 10px 0 0 10px;
}
.dPad:not(:root:root) {
  /* safari-fix */
  background-image: radial-gradient(circle 30px at 0 0, rgba(0, 0, 0, 0) 30px, #c2c2c2 30px, #c2c2c2 70px, rgba(0, 0, 0, 0) 70.001px), radial-gradient(circle 30px at 100px 0, rgba(0, 0, 0, 0) 30px, #c2c2c2 30px, #c2c2c2 70px, rgba(0, 0, 0, 0) 70.001px), radial-gradient(circle 30px at 0 100px, rgba(0, 0, 0, 0) 30px, #c2c2c2 30px, #c2c2c2 70px, rgba(0, 0, 0, 0) 70.001px), radial-gradient(circle 30px at 100px 100px, rgba(0, 0, 0, 0) 30px, #c2c2c2 30px, #c2c2c2 70px, rgba(0, 0, 0, 0) 70.001px);
}
@-moz-document url-prefix() {
  .dPad {
    background-image: radial-gradient(circle 30px at 0 0, rgba(0, 0, 0, 0) 29px, #c2c2c2 30px, #c2c2c2 70px, rgba(0, 0, 0, 0) 70px), radial-gradient(circle 30px at 100px 0, rgba(0, 0, 0, 0) 29px, #c2c2c2 30px, #c2c2c2 70px, rgba(0, 0, 0, 0) 70px), radial-gradient(circle 30px at 0 100px, rgba(0, 0, 0, 0) 29px, #c2c2c2 30px, #c2c2c2 70px, rgba(0, 0, 0, 0) 70px), radial-gradient(circle 30px at 100px 100px, rgba(0, 0, 0, 0) 29px, #c2c2c2 30px, #c2c2c2 70px, rgba(0, 0, 0, 0) 70px);
  }
}
.dPadLeft {
  top: 50px;
  left: 20px;
}
.dPadRight {
  top: 50px;
  left: 80px;
}
.dPadUp {
  top: 20px;
  left: 50px;
}
.dPadDown {
  top: 80px;
  left: 50px;
}
.dPadCenter {
  top: 44px;
  /*45*/
  left: 43px;
  /*45*/
  font-size: 24px;
}
div[class^="dPadButton"] {
  opacity: 0.95;
  background-color: #c2c2c2;
  padding: 5px;
  border-radius: 100%;
  z-index: 2;
  transition: color 0.4s, background-color 0.4s, background 0.4s;
}
div[class^="dPadButton"] > span {
  cursor: pointer;
}
.dPadButton1 {
  top: 10px;
  left: 130px;
}
.dPadButton2 {
  top: 45px;
  left: 137px;
}
.dPadButton3 {
  top: 80px;
  left: 130px;
}
div.dPadButton31 {
  top: 80px;
  left: 156px;
  /*163px;*/
  font-size: 10px;
  height: 30px;
  border-radius: 0 15px 15px 0;
  background: radial-gradient(circle at -10px 15px, transparent 16px, #c2c2c2 16px);
  background-color: transparent;
}
.dPadButton31 span {
  margin: 3px -3px 0 2px;
}
.dPadButton4 {
  top: 112px;
  left: 112px;
}
.dPadButton5 {
  top: 3px;
  left: 3px;
}
.dPadCamRaise {
  top: 10px;
  left: 103px;
  height: 49px;
  width: 35px;
  background: radial-gradient(circle at -40px 50px, rgba(0, 0, 0, 0) 50px, #c2c2c2 51px, #c2c2c2 71px, rgba(0, 0, 0, 0) 72px);
  border-radius: 5px;
}
.dPadCamRaise:not(:root:root) {
  /* safari */
  background: radial-gradient(circle at -40px 50px, rgba(0, 0, 0, 0) 50px, #c2c2c2 50.001px, #c2c2c2 72px, rgba(0, 0, 0, 0) 72.001px);
}
.dPadCamSink {
  top: 61px;
  left: 103px;
  height: 49px;
  width: 35px;
  background: radial-gradient(circle at -40px 0, rgba(0, 0, 0, 0) 50px, #c2c2c2 51px, #c2c2c2 71px, rgba(0, 0, 0, 0) 72px);
  border-radius: 5px;
}
.dPadCamSink:not(:root:root) {
  /* safari */
  background: radial-gradient(circle at -40px 0, rgba(0, 0, 0, 0) 50px, #c2c2c2 50.001px, #c2c2c2 72px, rgba(0, 0, 0, 0) 72.001px);
}
.dPadCamRaise span,
.dPadCamSink span {
  position: absolute;
  top: 11px;
  left: 7px;
}
.dPadCamRaise span {
  top: 20px;
}
.dPadSub {
  opacity: 0.95;
  position: absolute;
  top: 115px;
  left: 24px;
}
.dPadSub div {
  width: 35px;
  height: 20px;
  display: inline-block;
  background-color: #c2c2c2;
  text-align: center;
  border: 1px solid #c2c2c2;
  margin: -1px;
  margin-top: 5px;
  position: static;
}
.dPadSub div > span {
  user-select: none;
}
.dPadSubL {
  cursor: pointer;
  border-radius: 5px 0 0 5px;
}
.dPadSubR {
  cursor: pointer;
  border-radius: 0 5px 5px 0;
}
.fakeSelect {
  color: #d2d2d2;
  background: rgba(255, 255, 255, 0.1);
  transition: color 0.4s, backgorund-color 0.4s, background 0.4s;
}
.glOverlayContainer {
  position: relative;
  top: 1%;
}
.glPanel {
  position: absolute;
  opacity: 0.95;
  padding: 10px;
  min-width: 200px;
  background: radial-gradient(circle 10px at -6px 3px, transparent 15px, #c2c2c2 16px);
  pointer-events: auto;
  /*TODO: find workaround for cutout corner*/
  z-index: 2;
}
.glPanel select {
  background-color: #c2c2c2;
}
.glPanel .checkbox {
  margin-top: 5px;
  margin-bottom: 5px;
}
#fullOverlayContainer {
  position: absolute;
  z-index: 1000;
  height: calc(100% - 95px);
  width: calc(100% - 30px);
  background-color: #e1e1e1;
}
#fullOverlayContainer h2 {
  margin-top: 25%;
}
#glAlignContainer {
  position: absolute;
  top: -20px;
  left: 190px;
  white-space: nowrap;
}
#glDisplayPanel {
  left: 162px;
  top: 60px;
  min-width: 210px;
}
#glSensorOverlayConfPanel {
  top: 95px;
  left: 170px;
  min-width: 60px;
}
#glSettingsPanel {
  top: 16px;
  left: 25px;
  min-width: 210px;
}
#glSimuPanel {
  top: 127px;
  left: 136px;
  width: 210px;
  /*475px;*/
}
/* tree fixes: */
treecontrol {
  color: inherit;
}
.tree-branch-head {
  padding: 1px 5px;
}
.tree-full-line {
  display: inline-block;
  width: calc(100% - 20px);
  margin: 0 0 3px;
  padding: 0px 5px;
  white-space: nowrap;
}
.tree-full-line-noselect {
  display: inline-block;
  width: calc(100% - 20px);
  margin: 0 0 3px;
  padding: 0px 5px;
  white-space: nowrap;
}
treecontrol > ul > li {
  padding-left: 5px;
}
.tree-selected > .tree-full-line {
  background-color: #00adba;
  color: #FFFFFF;
}
.tree-selected > .tree-full-line-noselect {
  background-color: #ffffff !important;
  color: #575756;
  font-weight: normal;
}
.tree-full-line:hover {
  background-color: #51bec8;
  color: #FFFFFF;
}
.btn-black {
  color: #FFFFFF !important;
  background-color: #7d7e7d !important;
}
.uib-tab-heading > [class^="icon-"] {
  font-size: 20px;
}
.label-danger,
.label-default,
.label-primary {
  padding: 3px 6px;
  display: inline-block;
  margin: 2px;
}
.hulabel {
  position: absolute;
  min-width: 125px;
  min-height: 40px;
}
.icon-active {
  opacity: 1;
}
.icon-inactive {
  opacity: 0.3;
}
[ng-controller="IndexController"] {
  height: 100%;
}
#wrapper {
  min-height: 100%;
  height: 100%;
  overflow-y: auto !important;
}
#wrapper #page-wrapper {
  min-height: 100% !important;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 10px;
}
@media screen and (orientation: landscape) and (max-device-width: 75em) and (pointer: coarse), screen and (orientation: portrait) {
  /* scrollbar fixes */
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }
  .desktop-only {
    display: none !important;
  }
  .single-remain {
    width: 100%;
    text-align: center;
  }
  #breadcrumb-container {
    max-width: calc(100% - 170px) !important;
  }
}
body.mini-navbar .profile-element {
  display: block;
}
body.fixed-sidebar.mini-navbar .navbar-default .nav li a span strong {
  display: block;
}
body.mini-navbar .nav-header {
  padding: 25px;
  background-color: #ffffff !important;
}
.no-saved-filter {
  padding: 5px 10px;
}
.dashboard-fix {
  margin-right: -40px;
  margin-left: -40px;
}
.panel {
  background-color: inherit;
}
.bg-info {
  color: #575756;
  background-color: #b8e0e5;
}
.well {
  word-break: break-all;
}
select {
  border: 1px solid #e1e1e1 !important;
}
select:focus {
  box-shadow: 0 0 3px #00adba;
  outline: none;
  border-color: #00adba;
}
select:focus:not(:root:root) {
  box-shadow: none;
}
/* safari */
select:active:not(:root:root) {
  box-shadow: 0 0 3px #00adba;
}
/* safari */
selecT:focus a {
  outline: none;
}
.navbar-right {
  float: right;
}
.grid-stack-item-content {
  background-color: #ffffff !important;
}
/*------------------------------------*/
/*---------- report-stuff ------------*/
/*------------------------------------*/
[ng-repeat="page in rctrl.report.pages"] .ibox-content,
[ng-repeat="page in rctrl.report.pages"] .ibox-content *:not(.leaflet-marker-icon):not(.leaflet-marker-shadow):not(.leaflet-popup):not(.leaflet-popup-close-button):not(.leaflet-popup-tip-container):not(.glyphicon-exclamation-sign):not(.glyphicon-info-sign):not(.glyphicon-remove-sign) {
  background-color: #fff;
  color: #000;
}
[ng-repeat="page in rctrl.report.pages"] *.grid-stack-item-content {
  background-color: #fff !important;
}
[ng-repeat="page in rctrl.report.pages"] rect.highcharts-background {
  fill: #fff;
}
widgetlocationmap .leaflet-top.leaflet-left,
widgetlocationmap .leaflet-control-zoom {
  background-color: transparent !important;
}
div.modal-header,
div.modal-footer {
  border-color: #e7eaec;
}
.has-error .help-block {
  color: #e30613;
}
#canvas {
  max-width: 100%;
}
/*.childs-even > div:nth-child(even) {
    background-color: rgba(0,0,0,0.1);
}*/
.floor-panel:nth-child(even) {
  background-color: #f9f9f9;
}
.floor-panel:hover {
  background-color: #f5f5f5;
}
.room-panel {
  background-color: #ffffff;
}
.room-panel:hover {
  color: #000000;
  box-shadow: 0 0 5px #00adba;
}
.grid-stack > .grid-stack-item > .grid-stack-item-content {
  overflow: hidden;
  left: 7px;
  right: 7px;
}
.widget-location-map .leaflet-popup-content {
  min-width: 100px;
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  color: #575756;
  background: #ffffff;
}
.ui-draggable .ibox-title.locked {
  cursor: initial;
}
[image="true"] > img {
  display: block;
  max-width: 100%;
  height: auto;
}
.sidebar-collapse {
  height: calc(100% - 20px);
  overflow-x: hidden;
  overflow-y: auto;
}
.chosen-results::-webkit-scrollbar,
.nav-second-level::-webkit-scrollbar,
.sidebar-collapse::-webkit-scrollbar {
  width: 4px;
}
.version-container {
  border-top: 1px solid #575756;
  cursor: pointer;
}
body:not(.mini-navbar) .nav-second-level.collapse,
body:not(.mini-navbar) .nav-second-level.collapsing {
  opacity: 0;
}
.nav-second-level.collapse.in {
  opacity: 1 !important;
}
.nav-second-level {
  transition: opacity 0.25s;
}
.mini-navbar .nav-second-level {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 150px;
  z-index: 1;
  left: 70px !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.toggle-switch label {
  background-color: #ffffff;
  border: 2px solid #00adba;
  color: #00adba;
  user-select: none;
  width: calc(max-content + 30px);
  overflow: hidden;
  height: 22px;
}
.toggle-switch label i {
  background-color: #ffffff;
  border: 1px solid #00adba;
  height: 100%;
  padding: 2px 7px;
  position: relative;
  left: -1px;
  transition: 0.5s;
  z-index: 200;
}
.toggle-switch input[type="checkbox"]:checked ~ label i {
  left: calc(100% - 15px);
}
.toggle-switch label span {
  position: relative;
  padding: 0 5px;
  transition: 0.5s;
  display: block;
}
.toggle-switch input[type="checkbox"]:checked ~ label span:nth-child(2),
.toggle-switch input[type="checkbox"]:not(:checked) ~ label span:nth-child(3) {
  opacity: 0;
  transition: 0.2s;
}
.toggle-switch label span:nth-child(2) {
  top: -18px;
  padding-left: 20px;
}
.toggle-switch label span:nth-child(3) {
  top: -36px;
  padding-right: 20px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.animated {
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
  z-index: auto !important;
}
.date > h1.text-info:not(:hover) {
  color: #00adba !important;
}
.date > h1.text-info:hover {
  color: #51bec8 !important;
}
.weatherForecast > tbody > tr > td {
  padding: 4px 8px;
  vertical-align: middle;
}
[ng-repeat="log in logs.logfiles | orderBy: 'date'"] {
  display: inline-block;
  margin: 2px;
}
#sidebar {
  height: 100%;
}
@media (max-width: 768px) {
  .ibox-tools {
    float: right !important;
  }
}
.ibox-title {
  white-space: nowrap;
}
.ibox-title h5 {
  max-width: calc(100% - 17px);
  padding-right: 5px;
  overflow: hidden;
}
.ibox-tools {
  max-width: 100%;
}
.ibox-tools-long {
  overflow-x: auto;
  overflow-y: hidden;
  max-width: calc(100% - 150px) !important;
  white-space: nowrap !important;
}
.ibox-tools-long * {
  white-space: nowrap !important;
  float: none !important;
}
.ibox-tools-long .btn-group .btn + .btn {
  margin-left: -5px;
}
#breadcrumb-container {
  max-width: calc(100% - 230px);
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.ibox-tools::-webkit-scrollbar,
#breadcrumb-container::-webkit-scrollbar {
  height: 3px;
}
#movBar {
  border: 1px solid #e1e1e1;
  border-right: none;
  height: calc(100% - 50px);
}
#movBar > .scrollable-y {
  max-height: calc(100% - 50px);
}
#movBar .nav-tabs > li > a {
  padding: 10px 10px 10px 10px;
}
.dropdown-menu {
  color: #575756;
  background-color: #ffffff;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #575756;
  background-color: #e1e1e1;
}
.filelist {
  overflow: auto;
}
.rsql-dropdown {
  color: inherit;
  line-height: 1;
  text-align: left;
  font-weight: normal;
}
.rsql-dropdown .animated {
  padding: 6px 22px;
}
.rsql-dropdown > * > * > a {
  color: inherit;
  margin: 4px;
  text-align: left;
  font-weight: normal;
}
.rsql-dropdown:hover {
  color: #000000;
  text-decoration: none;
  background-color: #f5f5f5;
}
.device-tiles .list-group {
  display: inline-block;
  float: none;
  padding-right: 0;
}
@media (min-width: 992px) {
  .device-tiles .list-group.col-md-6 {
    width: calc(50% - 3px);
  }
}
@media (min-width: 1200px) {
  .device-tiles .list-group.col-lg-4 {
    width: calc(33.333% - 3px);
  }
}
.device-tiles .list-group:nth-child(4) {
  clear: left;
}
.hover-copy,
.hover-copy:hover {
  cursor: copy;
}
#detailPic {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  position: relative;
}
.updateable {
  /* propably soon deprecated */
  background-color: transparent;
  transition: background-color 0.5s linear;
  -moz-transition: background-color 0.5s linear;
  -webkit-transition: background-color 0.5s linear;
  -ms-transition: background-color 0.5s linear;
}
.updateable-active {
  /* propably soon deprecated */
  background-color: #00E466;
}
@keyframes flashing {
  /* color-change for .flash-up class*/
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #00adba;
  }
  100% {
    background-color: transparent;
  }
}
.flash-up {
  /* meant to replace .updateable class with less js required for timeouts, etc.*/
  background-color: transparent;
  animation-name: flashing;
  animation-duration: 1.5s;
}
.multiSensorLabel,
.objectInfoLabel {
  position: absolute;
  padding: 1px 3px;
  background-color: #c2c2c2;
  border-radius: 3px;
  pointer-events: none;
}
/* Cookie Bar */
#cookie-bar {
  background: #00adba;
  height: auto;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  padding: 15px;
  margin-left: -15px;
  width: calc(100% + 30px);
  text-align: center;
}
#cookie-bar .cb-message {
  opacity: 0.85;
}
#cookie-bar a {
  color: #FFFFFF;
  margin-left: 5px;
  text-decoration: underline;
}
#cookie-bar a:hover {
  opacity: 0.85;
}
#cookie-bar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
#cookie-bar.fixed.bottom {
  bottom: 0;
  top: auto;
}
#cookie-bar p {
  margin: 0;
  padding: 0;
}
.cg-notify-close {
  color: #575756 !important;
  text-shadow: none;
}
.panel-primary {
  border-color: #00adba;
}
.panel-primary > .panel-heading {
  background-color: #00adba;
  border-color: #00adba;
  color: #FFFFFF;
}
.td-button-grp {
  width: 1%;
}
.td-button-grp div {
  white-space: nowrap;
}
.td-button-grp [href]:not(disabled="disabled") {
  cursor: pointer;
}
.widgetDiv > div {
  overflow-x: hidden;
}
.ibox-content .tabs-container .panel-body {
  padding: 5px;
}
.b-b-none {
  border-bottom: none !important;
}
.b-t-none {
  border-top: none !important;
}
.o-none:focus,
.o-none:hover,
.o-none:hover:focus {
  outline: none !important;
}
g.highcharts-button rect {
  fill: #ffffff;
  stroke: #a1a1a1;
}
g.highcharts-button:hover rect {
  fill: #a1a1a1;
  stroke: #a1a1a1;
}
g.highcharts-button > path {
  stroke: #a1a1a1;
}
g.highcharts-button:hover > path {
  stroke: #FFFFFF;
}
.highcharts-contextmenu div div {
  font-size: 13px !important;
  color: #575756;
  padding: 3px 5px !important;
}
.highcharts-contextmenu div {
  background: #ffffff !important;
  color: #575756 !important;
}
.highcharts-contextmenu div div:hover {
  color: #FFFFFF !important;
  background-color: #00adba !important;
}
.highcharts-contextmenu hr {
  margin: 5px 0;
}
.energy-cockpit .list-group-item {
  background-color: rgba(255, 255, 255, 0.8);
  border-left: none;
  border-right: none;
}
.energy-cockpit .list-group-item:first-child {
  border-top: none;
  margin-top: -15px;
}
.energy-cockpit .chart-container svg {
  padding-top: 5px;
}
.energy-cockpit .chart-container text[x="0"] {
  display: none !important;
}
td > heatmap-gradient > canvas {
  /* expands administration-heatmap-table's gradients to full td's width. */
  height: 10px;
}
heatmap-gradient > canvas {
  width: 100%;
}
widgetanalysis .highcharts-title > tspan,
widgetanalysis .highcharts-subtitle > tspan,
widgetanalysis .highcharts-legend-item > text > tspan,
widgetanalysis .highcharts-axis-labels > text > tspan,
widgetenergycockpit .highcharts-axis-labels > text > tspan,
widgetenergymeter .highcharts-title > tspan,
widgetenergymeter .highcharts-subtitle > tspan,
widgetenergymeter .highcharts-legend-item > text > tspan,
widgetenergymeter .highcharts-axis-labels > text > tspan,
widgetspeedometer .highcharts-title > tspan,
widgetspeedometer .highcharts-subtitle > tspan,
widgetweatherforecast .highcharts-title > tspan,
widgetweatherforecast .highcharts-subtitle > tspan,
widgetweatherforecast .highcharts-legend-item > text > tspan,
widgetweatherforecast .highcharts-axis-labels > text > tspan,
.analysis-container .highcharts-title > tspan,
.analysis-container .highcharts-subtitle > tspan,
.analysis-container .highcharts-legend-item > text > tspan,
.analysis-container .highcharts-axis-labels > text > tspan {
  color: #575756;
  fill: #575756;
}
widgetspeedometer .highcharts-title,
widgetspeedometer .highcharts-subtitle {
  color: #575756 !important;
  fill: #575756 !important;
}
widgetspeedometer .highcharts-center-value,
.highcharts-center-unit {
  color: #575756;
  fill: #575756;
}
widgetspeedometer .highcharts-axis-labels > text > tspan {
  color: #575756;
  fill: #575756;
}
.widgetspeedometer-background {
  background-color: #e1e1e1;
  fill: #e1e1e1;
}
widgetspeedometer .ibox-content {
  padding: 0px;
}
.highcharts-background {
  fill: #ffffff;
}
.nav.nav-tabs li:not(.active):not(:hover) a {
  background-color: #e1e1e1;
  opacity: 0.5;
}
.mirror {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
widgetsysteminfo .popover-content {
  color: black !important;
}
div.popover-content {
  color: black !important;
}
.w-100-px {
  width: 100px !important;
}
.custom-field-row:first-child .bring-up {
  pointer-events: none;
  display: none;
}
.custom-field-row:last-child .bring-down {
  pointer-events: none;
  display: none;
}
.border-radius-label-warning {
  border-radius: 3px;
  padding-left: 3px;
  padding-right: 3px;
}
.info-icon-background {
  pointer-events: auto;
  background: radial-gradient(#ffffff 5.5px, transparent 5px);
}
.text-widget {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}
.insufficientSpace {
  background-color: #e30613;
}
.dbSizeContainer {
  color: #fff;
  font-style: bold;
  line-height: 1,33333;
  background-color: #00adba;
  border: 1px solid #00adba;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 18px;
}
mwl-calendar .cal-slide-content {
  background-color: #ffffff !important;
  box-shadow: none !important;
}
mwl-calendar .cal-day-today {
  background-color: #51bec8;
}
mwl-calendar .cal-day-open {
  background-color: #00adba;
}
mwl-calendar .badge-important {
  background-color: #a1a1a1;
}
mwl-calendar .badge {
  color: #FFFFFF;
}
mwl-calendar .cal-day-today span[data-cal-date] {
  color: #FFFFFF;
}
.cal-week-box-cell {
  display: none !important;
}
mwl-calendar .cal-row-fluid:hover,
mwl-calendar .cal-year-box .row:hover {
  background-color: #f5f5f5;
}
mwl-calendar .cal-cell:hover {
  background-color: #f9f9f9;
}
mwl-calendar .cal-slide-content {
  background-color: #00adba !important;
  border-right: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}
mwl-calendar .cal-day-tick {
  border: 1px solid #e1e1e1;
  border-top: 0 solid;
  border-radius: 0 0 5px 5px;
  background-color: #ffffff;
}
mwl-calendar .cal-day-weekend span[data-cal-date] {
  color: #e30613;
}
mwl-calendar .cal-month-box .cal-row-head [class*=cal-cell]:hover {
  background-color: #f9f9f9;
}
mwl-calendar .events-list {
  max-height: 47px;
  padding-left: 5px;
  overflow: hidden !important;
}
mw.clendar event-item #text {
  display: none !important;
}
.box-group {
  text-align: center;
  background-color: #FFFFFF;
  border: 1px solid #e1e1e1;
  padding: 5px;
  margin: 2px;
}
.slidecontainer {
  width: 100%;
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #ffffff;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.slider:hover {
  opacity: 1;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #FFFFFF;
  cursor: pointer;
}
.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #FFFFFF;
  cursor: pointer;
}
#mailsettings-bar {
  background: #ffd500 !important;
  height: auto;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  padding: 15px;
  margin-left: -15px;
  width: calc(100% + 30px);
  text-align: center;
}
#mailsettings-bar .mb-message {
  opacity: 0.85;
}
#mailsettings-bar a {
  color: #FFFFFF;
  margin-left: 5px;
  text-decoration: underline;
}
#mailsettings-bar a:hover {
  opacity: 0.85;
}
#mailsettings-bar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
#mailsettings-bar.fixed.bottom {
  bottom: 0;
  top: auto;
}
#mailsettings-bar p {
  margin: 0;
  padding: 0;
}
#freeVersionBadge {
  position: absolute;
  z-index: 999999;
  bottom: 20px;
  opacity: 75%;
  width: 100%;
  height: 100px;
  background: #337ab7;
  overflow: hidden;
  color: white;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
}
#freeVersionBadge p {
  -ms-transform: rotate(25deg);
  /* IE 9 */
  transform: rotate(25deg);
  margin: 60px 40px;
}
body.mini-navbar #freeVersionBadge {
  display: none;
}
#watermark {
  display: flex;
  position: absolute;
  width: 100%;
  flex-direction: column;
  z-index: 999999;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 100px;
  opacity: 10%;
}
.spinner-row {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.spinner-align-center-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.spinneroverlay {
  position: absolute;
  z-index: 1000;
  background-color: #e1e1e1;
  opacity: 0.7;
}
.spinnerRoomOverview {
  height: calc(100% + 20px);
  width: calc(100% + 20px);
  top: -5px;
  left: -20px;
}
.spinnerRoomEdit {
  height: calc(100% - 95px);
  width: calc(100% - 30px);
}
.pointer {
  cursor: pointer;
}
input:checked + .slider {
  background-color: #00adba !important;
}
.primaryBGContainer {
  color: #fff;
  font-style: bold;
  line-height: 1,33333;
  text-align: center;
  background-color: #00adba;
  border: 1px solid #00adba;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 18px;
  margin-right: 15px;
}
.primaryBGContainerLarge {
  color: #fff;
  font-style: bold;
  line-height: 1,33333;
  text-align: center;
  background-color: #00adba;
  border: 1px solid #00adba;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 24px;
  margin-right: 15px;
}
.primaryBGInfoLabel {
  color: #00adba;
  font-style: bold;
  line-height: 1,33333;
  text-align: center;
  background-color: #fff;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 21px;
  margin-right: 15px;
}
.required:after {
  content: " *";
}
.form-group-error {
  position: relative;
  margin-top: -12px;
  font-size: 12px;
  color: red;
  padding-left: 3px;
}
.event-item-action {
  color: #fff !important;
}
.daterangepicker select.minuteselect {
  background: none !important;
}
.daterangepicker select.hourselect {
  background: none !important;
}
h6 {
  font-size: 14px;
  font-weight: bold;
}
flex-row-content {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
flex-start {
  align-items: flex-start;
}
/* Also login background */
/* Also hover */
.chosen-container .chosen-results {
  color: #575756;
}
.chosen-container .chosen-results:hover {
  background: #FFFFFF;
  color: #575756;
}
.leaflet-popup-content-wrapper {
  border-radius: 4px;
}
.inspinia-notify.alert-danger {
  border-color: #e30613;
}
.inspinia-notify.alert-success {
  border-color: #00adba;
}
.cg-notify-message {
  border-radius: 0;
}
.fav {
  background-color: #9ecdcd;
}
.redlimit {
  background-color: #e31013;
  color: white;
  padding: 2px;
}
.login-background {
  background-position: 50% 50%;
  /* image centered on screen */
  background-size: 100%;
  height: auto;
  min-height: 100%;
}
.copy-content-left::after {
  content: 'STULZ GmbH';
}
.copy-content-right::after {
  content: '© 2014 - 2021';
}
.logo-insert {
  background: url("../images/logo-login.svg") no-repeat;
  background-size: 100%;
  width: 180px;
  height: 38px;
}
.logo-login {
  background-size: 100%;
}
.row-error .glyphicon-remove-sign {
  color: #e30613;
}
.row-warning .glyphicon-exclamation-sign {
  color: #ffd500;
}
.row-info .glyphicon-info-sign {
  color: #006598;
}
.text-error,
.text-danger:not(.active):not(:hover) {
  color: #e30613 !important;
}
.text-warning:not(.active):not(:hover) {
  color: #ffd500 !important;
}
.text-info:not(.active):not(:hover) {
  color: #00adba !important;
}
.tooltip-inner {
  opacity: 1;
}
.nav:not(.nav-second-level) > li > a {
  padding: 14px 20px;
}
#sidebar {
  box-shadow: 1px 0 5px 0 rgba(0, 0, 0, 0.2);
}
.sp-container a.sp-cancel,
.sp-container button.sp-choose {
  text-decoration: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  padding: 4px 7px !important;
  background-image: none !important;
  border-radius: 3px !important;
  border-width: 1px;
  border-style: solid;
  box-shadow: none !important;
}
.sp-container a.sp-cancel {
  border-color: #a1a1a1;
  color: #a1a1a1 !important;
  background-color: #ffffff;
}
.sp-container a.sp-cancel:hover,
.sp-container a.sp-cancel:focus {
  color: #FFFFFF !important;
  background-color: #a1a1a1 !important;
  border-color: #a1a1a1 !important;
}
.sp-container button.sp-choose {
  border-color: #00adba;
  color: #FFFFFF !important;
  background-color: #00adba;
}
.sp-container button.sp-choose:hover,
.sp-container button.sp-choose:focus {
  color: #00adba !important;
  background-color: #ffffff !important;
  border-color: #00adba !important;
}
