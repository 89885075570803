@main-background: #e1e1e1;
@main-foreground: #575756;
@logo: url("../images/logo-login.svg") no-repeat; //needs to be filled soon
@logo-width: 180px;
@logo-height: 38px;
@logo-headline: ""; //'CyberHub ECO.DC';
@login-background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../images/bg_stulz.jpg") no-repeat; //gradient is actually a weird fix/workaround?
@login-background-color: #ffffff;
@product-logo: 'CyberHub'; // Logo / Image on login screen
@copy-color: inherit; // Color of copyright notice on login

//@nav-a: #a7b1c2;
@nav-background: #ffffff !important; /* Also login background */ //also tooltip-color
@nav-background-pattern: url("../images/cyberHub_logo.svg") no-repeat;
@nav-background-highlight: #00adba; /* Also hover */
@nav-background-highlight-border: #b8e0e5; //#00adba;
@nav-background-hover: #51bec8; //#8dcfd6;
@nav-text-color: #000000;
@nav-text-color-highlight: #FFFFFF;
@nav-header-color: #000000;
@nav-header-color-highlight: #575756; //#FFFFFF;
@nav-headline: ""; //'Energie Management Server';

@breadcrumb-background: #ffffff;
@breadcrumb-border: 1px solid #e7eaec !important;
@breadcrumb-background-image: none; // Breadcrumb background pattern

@content-background: #ffffff;
@content-border-color: #e7eaec;
@content-background-image: none;

@table-border-top: 1px solid #e7eaec;
@table-striped-background: #f9f9f9;
@table-hover-background: #f5f5f5;
@table-hover-color: #000000; //#676a6c;

@form-control-background: #FFFFFF;
@form-control-color: #575756; //#e5e6e7;
@form-control-border: 1px solid @form-control-border-color;
@form-control-border-color: #e1e1e1; //#E5E6E7;
@form-control-disabled-background: #e1e1e1;
@form-control-disabled-color: #a1a1a1; //#676a6c;
@form-control-focus-border: #00adba !important;
@form-control-highlighted-color: @form-control-color;
@form-control-highlighted-background: @nav-text-color-highlight;
@form-control-highlighted-gradient-start: @primary-background; //#3875d7;
@form-control-highlighted-gradient-end: @primary-background; //#2a62bc;

@hr-border: 1px solid #eee;

@modal-background: #ffffff;
@modal-border-top: 4px #e7eaec solid;

@primary-color: #FFFFFF;
@primary-background: #00adba;
@primary-border: #00adba;
@primary-hover-color: #00adba;
@primary-hover-background: #ffffff;
@primary-hover-border: #00adba;
@primary-disabled-color: #e1e1e1; //inherit;
@primary-disabled-border: #51bec8;
@primary-disabled-background: #51bec8;

@white-color: #a1a1a1;
@white-background: #ffffff;
@white-border: #a1a1a1; //#e7eaec; //outline white buttons
@white-hover-color: #FFFFFF; //inherit;
@white-hover-background: #a1a1a1; //inherit;
@white-hover-border: #a1a1a1; //#d2d2d2;

@default-color: #FFFFFF;
@default-background: #c2c2c2;
@default-border: #c2c2c2;
@default-hover-color: #FFFFFF;
@default-hover-background: #bababa;
@default-hover-border: #bababa;

@danger-color: #FFFFFF;
@danger-background: #e30613; //#ed5565
@danger-border: #e30613; //#ed5565
@danger-hover-color: #e30613; //#FFFFFF;
@danger-hover-background: #FFFFFF; //#ec4758;
@danger-hover-border: #e30613; //#ec4758;

@btn-3d-active-background: #7d7e7d;

@btn-download-background: @white-background;
@btn-download-color: @white-color;
@btn-download-border: @white-border;
@btn-download-hover-background: @white-hover-background;
@btn-download-hover-color: @white-hover-color;
@btn-download-hover-border: @white-hover-border;

@btn-back-background: @white-background;
@btn-back-color: @white-color;
@btn-back-border: @white-border;
@btn-back-hover-background: @white-hover-background;
@btn-back-hover-color: @white-hover-color;
@btn-back-hover-border: @white-hover-border;

@btn-cancel-background: @danger-background;
@btn-cancel-color: @danger-color;
@btn-cancel-border: @danger-border;
@btn-cancel-hover-background: @danger-hover-background;
@btn-cancel-hover-color: @danger-hover-color;
@btn-cancel-hover-border: @danger-hover-border;

@btn-edit-background: @white-background;
@btn-edit-color: @white-color;
@btn-edit-border: @white-border;
@btn-edit-hover-background: @white-hover-background;
@btn-edit-hover-color: @white-hover-color;
@btn-edit-hover-border: @white-hover-border;

@btn-delete-background: @danger-background;
@btn-delete-color: @danger-color;
@btn-delete-border: @danger-border;
@btn-delete-hover-background: @danger-hover-background;
@btn-delete-hover-color: @danger-hover-color;
@btn-delete-hover-border: @danger-hover-border;

@btn-move-background: @white-background;
@btn-move-color: @white-color;
@btn-move-border: @white-border;
@btn-move-hover-background: @white-hover-background;
@btn-move-hover-color: @white-hover-color;
@btn-move-hover-border: @white-hover-border;

@btn-create-background: @primary-background;
@btn-create-color: @primary-color;
@btn-create-border: @primary-border;
@btn-create-hover-background: @primary-hover-background;
@btn-create-hover-color: @primary-hover-color;
@btn-create-hover-border: @primary-hover-border;

@btn-details-background: @white-background;
@btn-details-color: @white-color;
@btn-details-border: @white-border;
@btn-details-hover-background: @white-hover-background;
@btn-details-hover-color: @white-hover-color;
@btn-details-hover-border: @white-hover-border;

@btn-view-background: @white-background;
@btn-view-color: @white-color;
@btn-view-border: @white-border;
@btn-view-hover-background: @white-hover-background;
@btn-view-hover-color: @white-hover-color;
@btn-view-hover-border: @white-hover-border;

@btn-remove-background: @danger-background;
@btn-remove-color: @danger-color;
@btn-remove-border: @danger-border;
@btn-remove-hover-background: @danger-hover-background;
@btn-remove-hover-color: @danger-hover-color;
@btn-remove-hover-border: @danger-hover-border;

@btn-apply-background: @primary-background;
@btn-apply-color: @primary-color;
@btn-apply-border: @primary-border;
@btn-apply-hover-background: @primary-hover-background;
@btn-apply-hover-color: @primary-hover-color;
@btn-apply-hover-border: @primary-hover-border;

@btn-add-background: @primary-background;
@btn-add-color: @primary-color;
@btn-add-border: @primary-border;
@btn-add-hover-background: @primary-hover-background;
@btn-add-hover-color: @primary-hover-color;
@btn-add-hover-border: @primary-hover-border;

@btn-save-background: @primary-background;
@btn-save-color: @primary-color;
@btn-save-border: @primary-border;
@btn-save-disabled-color: @primary-disabled-color;
@btn-save-hover-background: @primary-hover-background;
@btn-save-hover-color: @primary-hover-color;
@btn-save-hover-border: @primary-hover-border;

@btn-close-background: @white-background;
@btn-close-color: @white-color;
@btn-close-border: @white-border;
@btn-close-hover-background: @white-hover-background;
@btn-close-hover-color: @white-hover-color;
@btn-close-hover-border: @white-hover-border;

@badge-danger-background: @danger-background;

@tooltip-color: #ffffff; //@main-foreground;
@tooltip-background: #a1a1a1;

@row-error-background: #fff;
@row-warning-background: #fff;
@row-informal-background: #fff;
@row-highlight-background: #b8e0e5;
//@row-highlight-color: #575756; //#575756 is main.less default.

@link-color: #00adba;
@link-hover-color: #51bec8;

@progress-background: #ccc;

.chosen-container .chosen-results {
    color: @form-control-color;
}

.chosen-container .chosen-results:hover {
    background: @form-control-highlighted-background;
    color: @form-control-highlighted-color;
}

.leaflet-popup-content-wrapper {
    border-radius: 4px;
}

.inspinia-notify.alert-danger {
    border-color: @danger-border;
}

.inspinia-notify.alert-success {
    border-color: @primary-background;
}

.cg-notify-message {
    border-radius: 0;
}

.fav {
    background-color: #9ecdcd;
    //color: #fff;
}

.redlimit {
    background-color: #e31013;
    color: white;
    padding: 2px;
}

