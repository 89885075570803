@import "../../../ci-sources/main.less";
@import "cyberhub-light.less";
@import "fontello/fontello.css";

.login-background {
    background-position: 50% 50%; /* image centered on screen */
    background-size: 100%;
    height: auto;
    min-height: 100%;
}

.copy-content-left::after {
    content: 'STULZ GmbH';
}

.copy-content-right::after {
    content: '© 2014 - 2021';
}

.logo-insert {
    background: @logo;
    background-size: 100%;
    width: 180px;
    height: 38px;
}
.logo-login {
    background-size: 100%;
}

.row-error .glyphicon-remove-sign { //icon might need to be changed
    color: #e30613; //ci-red
}

.row-warning .glyphicon-exclamation-sign { //icon might need to be changed
    color: #ffd500; //ci-yellow
}

.row-info .glyphicon-info-sign { //icon might need to be changed
    color: #006598; //ci-blue
}

.text-error,
.text-danger:not(.active):not(:hover) { //had to include both because somebody keeps changing classes!
    color: #e30613 !important;
}

.text-warning:not(.active):not(:hover) {
    color: #ffd500 !important;
}

.text-info:not(.active):not(:hover) {
    color: @link-color !important;//#006598
}

.tooltip-inner {
    opacity: 1.0;
}

//.mini-navbar .nav-header {
//    //.mini-navbar .logo-insert{
//    background: url("/assets/ci/cyberhub-light/images/cyberHub_logo_mini.svg") no-repeat;
//    //height: 64px;
//}

.nav:not(.nav-second-level) > li > a {
    padding: 14px 20px;
}

#sidebar {
    box-shadow: 1px 0 5px 0 rgba(0, 0, 0, 0.2);
}

.sp-container a.sp-cancel,
.sp-container button.sp-choose {
    text-decoration: none;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight:normal;
    padding: 4px 7px !important;
    background-image: none !important;
    border-radius: 3px !important;
    border-width: 1px;
    border-style: solid;
    box-shadow: none !important;
}

.sp-container a.sp-cancel {
    border-color: @btn-back-border;
    color: @btn-back-color !important;
    background-color: @btn-back-background;
}

.sp-container a.sp-cancel:hover,
.sp-container a.sp-cancel:focus{
    color: @btn-back-hover-color !important;
    background-color: @btn-back-hover-background !important;
    border-color: @btn-back-hover-border !important;
}

.sp-container button.sp-choose {
    border-color: @primary-border;
    color: @primary-color !important;
    background-color: @primary-background;
}

.sp-container button.sp-choose:hover,
.sp-container button.sp-choose:focus{
    color: @primary-hover-color !important;
    background-color: @primary-hover-background !important;
    border-color: @primary-hover-border !important;
}
